import React, { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Grid2Props } from "@mui/material";
import PaddedBox from "./PaddedBox";

export default function StatsSection() {
  const size = useContext(SizeContext);
  const large = size === "large";

  interface StatProps extends Grid2Props {
    num: string;
    fact: string;
    cite?: string;
  }

  function Stat(props: StatProps) {
    return (
      <Grid2
        container
        sx={{
          color: "common.white",
          mb: 1,
          textAlign: "center",
          p: "0.5rem",
        }}
        xs={large ? 4 : 12}
      >
        <Grid2 xs={12}>
          <Typography variant="h1" sx={{ fontSize: "4rem !important" }}>
            {props.num}
          </Typography>
        </Grid2>
        <Grid2 xs={large ? 12 : 12} sx={{ height: large ? "6rem" : "5rem" }}>
          <Typography variant="body1">{props.fact}</Typography>
          {!!props.cite && (
            <Box display="flex" justifyContent="center">
              <Typography variant="caption">{`— ${props.cite}`}</Typography>
            </Box>
          )}
        </Grid2>
      </Grid2>
    );
  }

  return (
    <PaddedBox orange sx={{ px: 4, pt: large ? 16 : 8, pb: 4 }}>
      <Grid2 container>
        <Stat num="430,000" fact="rapes in the UK last year" />
        <Stat num="70,000" fact="reported" />
        <Stat num="1,200" fact="rapists convicted" />
      </Grid2>
      <Box justifySelf="flex-end" sx={{ px: 10 }}>
        <Typography variant="caption">Source: ONS</Typography>
      </Box>
    </PaddedBox>
  );
}
