import React from "react";
import Typography from "@mui/material/Typography";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";
import PaddedBox from "./PaddedBox";
import { Box } from "@mui/material";

export default function AboutInfoSection() {
  const pdf = "enough_research_summary.pdf";
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <br />
      <br />
      <Typography variant="body1">
        Enough is not for profit<sup>*</sup>, investing everything back to
        increase access and impact.
      </Typography>
      <br />
      <Typography variant="body1">
        We have received advice from all the leading professionals and
        institutions to help create enough.
      </Typography>
      <br />
      <Typography variant="body1">
        Our goal is to give survivors a simpler, easier way to report and create
        real deterrence; and easy, low cost, access to support that works.
      </Typography>
      <br />
      <Typography variant="body1">
        For society, we want to dramatically increase reporting and access by
        creating services with transformationally lower costs.
      </Typography>
      <br />
      <Typography variant="body1">
        We started by speaking to survivors to understand how they feel and what
        they want. Enough is the service that survivors are asking for. Read it{" "}
        <a href={pdf} target="_blank">
          here
        </a>
        .
      </Typography>
      <br />
      <Typography variant="body1">
        Enough is funded by people and organisations acting to end rape.
      </Typography>
      <br />
      <Typography variant="body1">
        Please donate and help us end rape:
      </Typography>
      <ButtonLink
        target="_blank"
        sx={{ mt: 3 }}
        href={Links.Donate}
        theme="color-outlined"
      >
        Donate Now
      </ButtonLink>
      <br />
      <br />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography variant="caption">
          <sup>*</sup> CIC registered
        </Typography>
      </Box>
    </PaddedBox>
  );
}
