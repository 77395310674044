import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import PaddedBox from "./PaddedBox";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import PageContainer from "./PageContainer";

export default function BristolPage() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PageContainer>
      <PaddedBox sx={{ pb: 3 }}>
        <Typography variant="h3">Collect a kit from a safe space</Typography>
      </PaddedBox>
      <PaddedBox orange>
        <Grid2 container>
          <Grid2 xs={large ? 6 : 12} sx={{ pb: large ? 0 : 4 }}>
            <Typography variant="h3" color="white">
              Cafes
            </Typography>
            <Typography variant="body1" color="white">
              <ul>
                <li>Burra, Redland</li>
                <li>Cafe Kino, Stokescroft</li>
                <li>Gather Coffee, Part St</li>
                <li>Interlude Coffee, St Michaels</li>
                <li>Society Cafe, Farr’s Lane</li>
                <li>The Colombian Company: City Centre, Whiteladies</li>
              </ul>
            </Typography>
          </Grid2>
          <Grid2 xs={large ? 6 : 12}>
            <Typography variant="h3" color="white">
              Pubs/ bars/ clubs
            </Typography>
            <Typography variant="body1" color="white">
              <ul>
                <li>Birkett Tap, City Centre </li>
                <li>Bishops Tavern, Gloucester Rd </li>
                <li>Bomba, Gloucester Rd </li>
                <li>Brass Pig, Triangle </li>
                <li>Brewdog, Baldwin St </li>
                <li>Brewhouse, Cotham Hill </li>
                <li>Canteen, Stokescroft </li>
                <li>Ciderpress, Gloucester Rd </li>
                <li>Daisys, Triangle </li>
                <li>Den & Terrace, Clifton </li>
                <li>Gallimaufry, Gloucester Rd </li>
                <li>Hope & Anchor, Jacob Wells Rd </li>
                <li>Jersey Lily, Redland </li>
                <li>Left Handed Giant, Redcliffe </li>
                <li>Revolution, City Centre </li>
                <li>Slug and Lettuce, City Centre </li>
                <li>SWX, City Centre </li>
                <li>Steam, Whiteladies Rd </li>
                <li>White Harte, Park Row </li>
                <li>Walkabout, City Centre </li>
                <li>Watershed, City Centre </li>
              </ul>
            </Typography>
          </Grid2>
        </Grid2>
      </PaddedBox>
      <PaddedBox>
        <Typography variant="h3">Access additional services</Typography>
        <br />
        <Typography variant="body1">
          You can collect STI kits or have them delivered straight to you:{" "}
          <Link to="https://www.unitysexualhealth.co.uk">
            https://www.unitysexualhealth.co.uk
          </Link>
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
