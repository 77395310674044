import * as React from "react";
import { Typography } from "@mui/material";
import PageContainer from "./PageContainer";
import FAQ from "./FAQ";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

export default function AdmissibilityPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <FAQ
          full
          title="Is Enough evidence admissible in Court?*"
          variant="subtitle1"
        >
          <Typography variant="caption">
            *Advice from King’s Counsel with experience in the admission of DNA
            evidence and the prosecution of serious sexual assaults
          </Typography>
          <br />
          <br />
          <Typography variant="caption">
            Few cases ever reach court (about 3% of complaints).
            <br />
            <br />
            Of those, the fact of contact with a particular individual and the
            location where contact occurred are very often not disputed; it
            follows that DNA evidence recovered using the Enough method is only
            likely to be considered to be potentially relevant in a limited
            number of cases.
            <br />
            <br />
            The methods used by medical professionals at SARCs mean that DNA and
            other samples recovered during those processes provide more
            information and are therefore more likely to be relevant and
            rendered admissible in a wider range of criminal prosecutions.
            <br />
            <br />
            The manner in which any evidential sample is retrieved, analysed and
            stored could well be the subject of challenge in a criminal trial.
            Anyone involved in that process, including survivors using Enough,
            could be questioned and challenged about that in court if the
            prosecution choose to attempt to rely on the sample; whether they
            attempt to do so is a matter solely for the prosecuting lawyers.
            <br />
            <br />
            In any event, there are no guarantees that evidence relating to a
            DNA sample will be deemed admissible at trial. Each case will depend
            on its own circumstances.
          </Typography>
        </FAQ>
        <br />
        <FAQ
          full
          variant="subtitle1"
          title="If the fact of contact by a particular individual is in dispute, it is conceivable that DNA evidence gathered using Enough could be deployed by the prosecution and deemed relevant and admissible by the court in a limited range of cases."
        >
          <Typography variant="caption">
            In the first instance, the court would have to be satisfied, by the
            survivor’s own testimony, that the sample retrieved is genuine, free
            from contamination and has been collected and (whilst in their
            custody) retained in accordance with the instructions provided with
            our packs.
            <br />
            <br />
            The court would also expect to receive other evidence in order to
            establish the reliability of the sample. In circumstances where this
            can be established, the court would still be obliged to consider
            whether any resulting DNA evidence is relevant to the issues in the
            case and ultimately whether its admission into evidence is fair to
            the parties before allowing any party to rely upon it.
            <br />
            <br />
            Forensic evidence retrieved by medical professionals and criminal
            investigators is expected to be handled in line with strict forensic
            guidelines. This enhances the prospects of admissibility and
            maximises the potency of such evidence.
            <br />
            <br />
            Whilst objections to such evidence are rare, it does not insulate
            DNA evidence gathered in this way from challenge or guarantee its
            admissibility.
            <br />
            <br />
            Survivors can anticipate that samples retrieved using Enough are
            likely to be subjected to challenge even if the prosecution choose
            to rely upon them and whether or not they are admitted into evidence
            at trial.
            <br />
            <br />
            It is also possible that the defence may seek to rely on the samples
            if, for example, DNA from a third party which could exculpate the
            defendant is identified.
            <br />
            <br />
            In order to maximise the potential admissibility of DNA evidence
            gathered using Enough, our DNA kit was developed, and is produced
            by, the same people that develop and produce DNA kits for the
            British police.
            <br />
            <br />
            Enough has obtained this advice from King’s Counsel with experience
            in the admission of DNA evidence and the prosecution of serious
            sexual assaults in providing this aspect of the guidance.
            <br />
            <br />
            25.03.15
          </Typography>
        </FAQ>
        <br />
        <ButtonLink href={Links.Report} theme="filled">
          Back to Reporting
        </ButtonLink>
      </PaddedBox>
    </PageContainer>
  );
}
