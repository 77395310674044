import * as React from "react";
import { Typography } from "@mui/material";
import PageContainer from "./PageContainer";
import FAQ from "./FAQ";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

export default function ShouldIReportPage() {
  return (
    <PageContainer>
      <PaddedBox>
        <Typography variant="h3">Should I report it?</Typography>
        <br />
        <FAQ
          full
          variant="subtitle1"
          title="Reporting helps stop rapists doing it again"
        >
          <Typography variant="caption">
            There are 3 <b>reporting</b> options:
          </Typography>

          <Typography variant="caption">
            <ol>
              <li>
                Go to a <b>SARC</b> to get a complete forensic examination,
                immediate 24 hr in person care for all your needs, and advice
              </li>
              <li>
                Report to the <b>Police</b>. They will possibly refer you to a
                SARC for a full forensic examination
              </li>
              <li>
                Report with <b>Enough</b>. Self-test simply and discreetly and
                write a time-stamped testimony. Create deterrence and decide
                later if you want to report further.
              </li>
            </ol>
          </Typography>

          <Typography variant="caption">
            You can also go to a Sexual Assault Referral Centre (SARC) first to
            get an examination while you decide
          </Typography>
          <br />
          <ButtonLink href={Links.Report} theme="filled">
            Report Now
          </ButtonLink>
        </FAQ>
        <br />
        <FAQ full variant="subtitle1" title="Should I go to a SARC? ">
          <Typography variant="caption">
            Go to a Sexual Assault Referral Centre (SARC) to get immediate 24hr
            care for all your needs, a complete forensic examination, and advice
            on next steps.
            <br />
            <br />
            A complete forensic examination will ensure that the most evidence
            is professionally collected and has the best likelihood of being
            relevant and admissible, helping achieve an eventual prosecution in
            a wider range of criminal prosecutions.
            <br />
            <br />
            The manner in which any evidential sample is retrieved, analysed and
            stored could well be the subject of challenge in a criminal trial.
            Anyone involved in that process, including survivors using Enough,
            could be questioned and challenged about that in court. There are no
            guarantees that evidence relating to a DNA sample will be deemed
            admissible at trial. Each case will depend on its own circumstances.
            <br />
            <br />
            The SARC is independent and can help you decide next steps without
            any pressure to report to the police
          </Typography>
          <br />
          <ButtonLink
            href="https://www.nhs.uk/service-search/sexual-health"
            theme="filled"
            target="_blank"
          >
            Find my nearest SARC
          </ButtonLink>
        </FAQ>
        <br />
        <FAQ full variant="subtitle1" title="Should I report to the Police?">
          <Typography variant="subtitle1">
            The police themselves say this is a personal decision*
          </Typography>
          <Typography variant="caption">
            <ul>
              <li>It can help emotionally as part of the recovery process.</li>
              <li>
                Where rape or sexual assault happened in a relationship, as part
                of a pattern of domestic abuse, reporting can help end the
                relationship and escape the violence.
              </li>
              <li>
                It could be the first step on the road to a guilty verdict in
                court.
              </li>
              <li>
                It can help them solve other cases and stop the attacker
                assaulting someone else.
              </li>
            </ul>
          </Typography>
          <Typography variant="caption" alignSelf="end">
            *www.police.uk
          </Typography>
          <Typography variant="caption" sx={{ mt: 1 }}>
            As the police themselves say, it is totally normal to be very unsure
            about reporting rape or sexual assault to the police, and about 85%
            choose not to. It is a survivor’s right to report what happened to
            them when and to whom they choose.
          </Typography>
          <br />
          <ButtonLink
            href="https://www.police.uk/contact/find-a-police-station/"
            theme="filled"
            target="_blank"
          >
            Find out more
          </ButtonLink>
        </FAQ>
        <br />
        <FAQ full variant="subtitle1" title="Should I report with enough?">
          <Typography variant="caption">
            <ul>
              <li>
                <b>Enough</b> provides an alternative for survivors to deter
                offenders from engaging in sexual assaults, and support them
                through their recovery.
              </li>
              <li>
                <b>Enough</b> is quick, easy, and totally private
              </li>
              <li>
                <b>You</b> can share the aim of creating real deterrence and
                encouraging other survivors to do the same
              </li>
              <li>
                <b>It is 100% legal to report to Enough</b>. You hold the power
                to decide exactly what you want to do, and when. No-one else.
              </li>
              <li>
                An Enough report can have 3 parts:
                <ol>
                  <li>
                    <b>An anonymous statement</b> published to thousands on our
                    website and in social media, telling the world what you want
                    (with no names or details capable of identifying the
                    offender)
                  </li>
                  <li>
                    <b>A private record</b> (optional) eg: who, what, where,
                    when; encrypted in a private account for your eyes only.
                    This is saved and time stamped. You can download it any time
                    you want and take it to the police in the future. This can
                    create powerful deterrence.
                  </li>
                  <li>
                    <b>Self collect a DNA sample</b> from your own body
                    (optional). Get it analysed at our lab within 48 hours. The
                    result is saved to your encrypted account, and half the
                    sample is frozen at the lab for 20 years. You can allow
                    police access to it if you ever want to. This could create
                    powerful deterrence.
                  </li>
                </ol>
              </li>
            </ul>
          </Typography>
          <Typography variant="caption">
            Students in Bristol are choosing to report to Enough hundreds of
            times more often than they are to their University; and about 6x
            more often than they are to the Police.
          </Typography>
          <br />
          <ButtonLink href={Links.Report} theme="filled">
            Report with enough
          </ButtonLink>
        </FAQ>
        <br />
        <FAQ
          full
          variant="subtitle1"
          title="Is Enough evidence admissible in Court?*"
        >
          <Typography variant="caption">
            It can be.
            <br />
            <br />
            DNA evidence is only relevant, and will only be submitted to a
            court, if contact with a particular individual and location are
            disputed
            <br />
            <br />
            <b>
              If the survivor will testify that the DNA sample was correctly
              collected following the instructions to avoid contamination; then
              an Enough DNA sample can be admissible*
            </b>
            <br />
            <br />
            Not even police DNA is absolutely 100% guaranteed admissible in
            court, neither is an Enough sample.
            <br />
            <br />
            Enough’s DNA kit was developed, and is produced by, the same people
            that develop and produce kits for the British police
          </Typography>
          <br />
          <ButtonLink href={Links.Report} theme="filled">
            Report with enough
          </ButtonLink>
          <br />
          <br />
          <Typography variant="caption">
            *Full legal opinion by a leading KC Rape Barrister,{" "}
            <a href={Links.Admissibility}>see here</a>.
          </Typography>
        </FAQ>
      </PaddedBox>
    </PageContainer>
  );
}
