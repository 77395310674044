import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQPoliceSection() {
  return (
    <FAQSection title="Police">
      <FAQ title="Should I report to the police and enough?">
        <Typography variant="body2">
          The aim of Enough is deterrence not immediate criminal justice. If you
          want to report to the police or a SARC, you should go there first and
          get a full forensic examination.
        </Typography>
        <Typography variant="body2">
          You can report with enough afterwards, and access our recovery
          content. If you report and self-test with Enough, you can pass the
          information onto the police later (including a frozen portion of DNA)
          for them to use in your case.
        </Typography>
      </FAQ>

      <FAQ title="Will Enough DNA results be admissible in court?">
        <Typography variant="body2">
          Only the judge decides admissibility on a case by case basis. DNA
          evidence is only relevant where the perpetrator denies they were
          present, and it can always be challenged, whoever collects it. If a
          survivor will testify that an Enough sample was collected following
          the instructions then leading lawyers confirm it can be admissible.
          The kit was developed and made by the same people that make kits for
          the police.
        </Typography>
      </FAQ>

      <FAQ title="Will the police take my results seriously?">
        <Typography variant="body2">
          The police know that most survivors are not reporting to them. The
          police will consider any relevant information for a case.
        </Typography>
      </FAQ>

      <FAQ title="What about false allegations?">
        <Typography variant="body2">
          Perpetrators names are not publicized by enough. They are private to
          the survivor, and no allegation can be made through the platform
        </Typography>
        <br />
        <Typography variant="body2">
          False allegations are rare, less than 1% of rapes. A man is 10 times
          more likely to be raped than falsely accused.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
