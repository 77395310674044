export default [
  "it was my older sister. i didnt understand what was happening. i was 12",

  "i thought it was okay. He said it was normal. I was 12 and he was 16",

  "My cousin sibling introduced sex to me and would make me touch them. I told my mom about it, she told me to keep my mouth shut",

  "He watched his cousin assault me, then hit me because i ‘cheated’",

  "He made me feel so guilty. I though I was in the wrong for saying no",

  "You cant consent if you are asleep. Someone having sex with you while you are asleep is not sex it is rape. Even if you wake up and cant move or speak. Its still rape.",

  "He was my first bf. Because there was no intimacy inside his home he wanted to do it in the alley. I said “no” several times . He turned me around put my pants down and just went in. I was crying.",

  'We were married. It only happened once, but it broke me. What he did to me, as a whole, broke me. My mother told me to "just get over it" when I told her years after I left him. I still feel ashamed.',

  "I just recently realized that I was raped, having sex to avoid conflict is rape.",

  "He told me that he understood I didn’t want it, but he couldn’t possibly be classified as a rapist because rapists are monsters",

  "She said she heard me say no, but that it wasn’t *rape* because rape is so serious and two girls cant “have sex”.",

  "He said my “no” wasn’t serious enough. Did he not feel my nails penetrating his skin? Did he not feel the air leaving my lungs? Did he not hear my screams muffled by his pillow? Of course he did.",

  "I reported my rape in hopes that I am protecting other women from the heinous acts committed upon me. Battling the system is exhausting but will bring you a sense of peace and closure. IT IS WORTH IT!",

  "Since that night I’ve thought I could never recover. I pray no one else has to go through what I’ve felt. For him it was a small event but for me it nearly ruined my entire life. Coercion is rape.",

  "He sexually abused me for 6 months and said that I should’ve said no. I cried and pleaded every time before he would hurt me.",

  "Every time I look in the mirror, I see the shell of a woman I once was, all because another person couldn’t understand the word “No”.",

  "It was someone I worked with. We had been at a gig and I was drunk. Afterwards, he pulled me into a park, smashed my head against a tree and assaulted me.",

  "He raped me in the middle of a corn field. Dripping sweat and I remember feeling like I had left my body because I didn’t know how to say no. 24 years later and it still haunts me.",

  "I was only 13. I wish you could feel the way I felt. But you’ll never be the child in the situation. I want my favorite sport back.",

  "I don’t know if you know you did anything wrong. I didn’t say no but I didn’t say yes. You were supposed to be my boyfriend. The strangest part is after everything I miss you.",

  "I remember staring out the window wondering how many bones I would break if I jumped out the 5 stories as he pulled at my clothes and ignored my please of “no” and “stop”",

  "It was my boyfriend of 2 years. I was in pain, crying, and said I didn't know if I wanted to keep going, but he did. I can't trust anyone now",

  "I don't feel like I can even call it rape because he was ftm. He kept pushing and I gave in.",

  "It was the last night out of first year of uni, and we were all super close as a flat, but after some drinks one of the boys followed me into my room just before we were due to go out and touched me",

  "Two men, on separate occasions on the same holiday, took pictures and videos of me from behind while I was wearing a bikini, and another up my dress as I walked past a man sat on the staircase.",

  "My (now ex) boyfriend kept begging me till i said yes, he said it hurt him and i needed to do it.. i was 12, he was 14.",

  "I lost my virginity at 16 be being raped. It destroyed my life and led to me dropping out of school with no qualifications.. I was suicidal. I am now 20 and attended a university interview this mornin",

  "My girlfriend tried to rape me in my sleep. It's been seven years, and I don't think I've fully processed it yet. She was angry with me when I tried to stop her.",

  "My ex boyfriend raped me while I told him repeatedly to stop. He admitted it was rape via texts - I reported it to the police and it wasn’t enough, he has raped another girl since…",

  "He was a friend of my boyfriend at the time, I begged him to stop and told him he was “acting like a predator” while he pushed his fingers inside me. My ex knows and is still friends with him…",

  "I woke up to knees pinning my shoulders and a penis being forced in my mouth…by my husband…being ‘spontaneous’….whilst I had an afternoon nap",

  "When I was 3 my cousin started touching me, I didn’t understand why. Things got worse when her older (16) boyfriend came in and raped me, I was 12 when it finally ended this would happen very often.",

  "I remember thinking please kids dont come out of your rooms, I blacked out while he and he’s best friend was egging each other assaulting me. Goldcoast Area, Switzerland",

  "i was raped 3 times in two different relationships, i never had the guts to report them when they happened and i now suffer with ptsd. i’m worried i won’t find comfort in relationships because of it.",

  "My ex raped me on my 21st birthday. We broke up not long after, when I told him that was my final reason to go he said 'it wasn't my intention to rape you, it just happened like that'",

  "I was 15 and he was 17 when he put a dresser in front of the door and told me he would kick me out if I didn’t get down on my knees. It was 2 am $ I didn’t think I had a choice. He was drunk, I wasn’t",

  "He was my long term boyfriend. He beat me, hospitalized me, drugged me, and wanted to rape me sober one last time as we broke up. I didn’t fight back so he would leave sooner.",

  "His crew gang raped me. I thought he was the first black man I had been with, but when I came back from my blackout and said that he laughed in my face and told me I had been passed around",

  "He acted like he put the condom on, because I told him he had to wear one. I was on too many drugs to notice he actually didn’t, until he said “don’t worry about that, we can use one later” inside me",

  "About a friend: When she told me (a guy) about her experiences with sexual violence and rape it really 'flooded' my eyes. I want people to know that they are not alone. Speak about it.",

  "After he raped me, he told me that I should get over it. That it was a just a misunderstanding. He never got arrested. He never got charged. He never got convicted. He never got jailed or imprisoned.",

  "i was 12 and he was 18. i didn’t think it was rape but soon later i found out it was. i regret it so much.",

  "I didn’t tell anyone for almost two years. I was, and sometimes still am, ashamed that it happened. I hate that I have to be scared, it’s messed up.",

  "He was my first boyfriend, and my first sexual experiences. Now any sexual experience I have is tainted by what he did to me",

  "We were “bff” and he did it to me in my bed, I slept on the floor of my bathroom for weeks. I was 13.",

  "I’ve been sexually assaulted a total of 3 times. But after the first, my parents started deciding it’s my own fault. I became a teen mom from the result of rape. I have never felt more alone.",

  'The guy who raped me 2 years ago still blocks anyone who tries to comfront him about it. He knows hes right he just says its "stupid gossip" i want to put him out there but im scared of him.',

  "I met a boy at a club and we kissed. I'd told him I did not want to sleep with him multiple times. He walked me home and then said he needed to use my toilet as an excuse. Then he did it. I cried.",

  "He took me upstairs where we were alone and kissed me. I was 11. To this day I still can’t say his name. It took me five years to feel comfortable telling anyone.",

  "He told me he loved me. He slowly began to say that I was the only reason he didn't hurt himself. Then, it became conditional. \"If you don't do this, I'll hurt myself. You love me, don't you?\"",

  "we’re not alone",

  "She was my long time friend from school. She waited until I was drunk, then forced me into it. The threats against me to keep quiet followed soon after. We still live in the same town.",

  "I am a deep sleeper. He told me about his 'game' to see if he could 'finish' before I woke up or try not to wake me, told me (after 3years) he had been doing this since 2-3months in. I never woke",

  "He said he needed 30 seconds more and continued to rub himself against me for 10 more minutes no matter how many times I said I felt uncomfortable he’d respond with but you don’t complain though",

  "I'm sick of feeling fear everywhere I go in case i might see him. I always feel his hands all over me. I feel so unsafe in this world.",

  "He took the condom off without telling me and gave me an STI. I never felt valid to be upset, since the protected sex was consensual. It was years ago, but I think about it constantly.",

  "He took me to a car park after over half an hour of telling him i didn’t want to do anything, repeatedly moving his hands off me and trying to reason with him I gave up despite never wanting to do sex",

  "The detective dropped my case without me knowing. He told me to think about my attackers family because he had daughters. I was 19.",

  "My abusive ex told me if I didn’t he would make my life hell. This happened weekly. I thought it was normal.",

  "On my 18th birthday my ex pulled me by my hair and held me down, he tore me and police did nothing",

  "My ex partner wanted sex all the time, after a while of being punished emotionally for not giving it up, I'd just give it up. I now understand it was non-consentual & manipulative.",

  "my vulnerability was not consent. i asked you to come over , i asked you for help .you sexually assaulted me when i answered the door in tears .i never want to see you again or ask for help again",

  "he didnt ask because he would have carried on regardless. my wrists were bruised days later, every-time a man gets on top of me i see his face . i later found out he had a gf im glad it was me not her",

  "No matter how many times I shower and wash my bed and my bedding I still feel dirty and gross",

  "I was drunk and naive, I didn't know any better and she took advantage of that",

  "even though the assault wasn't that bad. when I spoke up about it, I was called a slut by him and my friend group, I had reported it to tons of different teachers but no one punished him",

  "As a child I was touched by my cousin, and to be honest my child self was so traumatized that I blocked it all out. My family still asks me if I want to go to events where he is, he faced 0 consequenc",

  "Midway through having sex I got a gut feeling that something was wrong. Told him to pull out and turns out he stealthed me and taken the condom off without me knowing. I’ve never felt so violated.",

  "I lost my virginity at 14. I am now 16 and have just gotten out of a relationship where I was regularly raped. It's so hard my friends are having their first times now, I can't describe the feelings",

  "My sisters ex- and his 2 friends drugged and raped me ! We hated each other - not knowing what they did or what I did meant blocking it.I fantasize about revenge- he’s done it to others- he’s a father",

  "My first boyfriend coerced me into anal sex. It hurt bad, and he didn’t stop when I asked. He finished as I cried. He said “don’t tell me it hurts because I know it stretches.” I still have a scar.",

  "I woke up with my underwear down and my ex attempting to anally penetrate me while I was asleep. He’d asked for anal before but I said no. He said he was half asleep and didn’t know he was doing it.",

  "I had a manipulative boyfriend from when I was 13-18 years old who I couldn’t escape. He made me feel like I owed him by threatening self harm. I was abused and raped by coercion for my entire teens.",

  "Sometimes i feel like writing a book about what happened. But i never do because what if no one reads it? Also what would people in my real life have to say about it. Feels so vulnerable",

  "I was so young i didnt understand it was sex until years later. Now at 26 im still scared of touch.",

  "I was 15 and babysitting. He lied lady was coming up stairs to flat . I fought so hard He pulled out my tampon. I was left lying there. He left. I had to clean blood off carpet . Told nobody.",

  "I took him to court. He’d texted me apologizing afterwards, but they still found him not guilty.",

  "Just got told they aren't arresting him but letting him come for voluntary interview. He has threatened me with a zombie knife. His school is next to mine. He knows where I live. There won't be bail.",

  '"I bet you begged for it!" I was 3 years old. I was nonverbal.',

  "I was riding a public bus at midnightand a random guy rubbed his di*k on my butt, later I turned to confront and he grabbed me and slouched for my face, I slapped him and got off bus, I felt disgust.",

  "My boyfriend at the time - on our first time together he put it in anally on “accident” and was getting impatient with me as I had to stop from the pain.",

  "Me:*sleepily*what are you doing? Him:I just wanted to see if I could He was trying to rape me while I slept.No shame/embarrassment when I called him out, acted like it was a common challenge men do",

  "My rapist is trans (ftm). I got told by the sheriffs department that it wasn’t rape because two “girls” can’t rape each other since there’s no penis. That was a lie.",

  "I was walking home from university in the middle of the afternoon .... Cause I froze and didn't say no ( I was scared) \" technically that's not rape \" that's what he kept saying to me",

  "I was a child (7 yr old) so I didn't knew what was happening, there was an uncle who were touching and rubbing my thighs.",

  "My uncle raped me on the night after my 8th birthday. He shoved a wet towel in my mouth so I couldn’t breathe. No one in my family knows.",

  "He was my boyfriend. I wasn’t able to move or respond. But he didn’t stop. Multiple times. I didn’t know this wasn’t “normal”. I couldn’t consent. He admitted it, to me, but to the world, never.",

  "Sick with COVID, barely able to breathe, he shoved me down. I tried to protest, my voice failed. My hair was yanked, and I gasped for air, forced into something I didn’t want down on him.",

  "It happened 3 years ago but I can’t get over it. I still can’t have sex without feeling scared or disassociating. I feel disconnected from my body",

  "I can't even write it. I have so much shame. Writing it down makes it feel all over again. I was 7. I'm now 35. It still feels too raw.",

  "When I confronted him about what he did to me, he told me “well who else am I going to have sex with”.",

  "I told him to stop because I was in pain and he shouted no and held me down, I just froze. Because he was my boyfriend I didn’t know it was rape and stayed with him at the time.",

  "My cousin stuck his hand up my skirt at a family event. Started texting me for nudes. When I told his gf they left and that side of the family blocked me. I’m now labeled as difficult.",

  "My sister sexually abused me for years. She said if I told she’d lie and get me in trouble for not ‘playing fair’. I never told anyone because I’m a year older I didn’t think they’d believe me.",

  "I woke up to him raping me. I’m deep sleeper & thought it was a nightmare from previous trauma. I woke up and asked him about it. He said he asked me and I said yes. I was asleep. couldn’t consent.",

  "I was spiked on a night out in February of my first year of uni. He ended up being a student and found ways to follow me around campus even touching me in passing.",

  "He was so rough I had tears similar to childbirth. He wouldn’t take no after the doctor said I couldn’t have sex. He wouldn’t stop when I bled. Took a long time to realise that’s not love.",

  "He kept me in his house for five days no one knew where I was. He raped me repeatedly and told me if I told anyone he would rape my sister in front of me. He’s free and works for *****",

  "I never tell anyone since I don’t think it would be seen as anything. But he touched me, i was 8, he didn’t do anything to me but at the same time he did. Right next to his daughters.",

  "i was 11 years old and he told me i looked too feminine so i should be treated like a woman. no one believed me",

  "He told me I could sleep in his bed & he’d take the couch because I was too drunk to drive home. I remember going to sleep fully clothed. I woke up without underwear/pants. He had 3 daughters.",

  "We were in the backseat of a car fully stuffed with my best friends after Hoco, where he put his hand up my dress, and all I could do was try to pull down my dress, close my legs and elbow him to stop",

  "He took advantage of me whilst I was very drunk and then threatened me after to not tell anyone. I was too ashamed to tell anyone till months later.",

  "August 2023 i was raped by an ex-fwb. he coerced me. i got the confidence to speak about it about a year after. i've been harrassed and threatened with death by him, his brother and female friends",

  "I was 14, he was 22. He was the older brother of a friend. We were supposed to watch the game on TV. He raped me, and bragged about it later. I was harassed at school because of that, for months.",

  "I was like 7 months pregnant with his baby, asleep on his nans sofa bed and he started pushing in and didn’t listen to me crying telling him to stop, it happened most mornings",

  "He did it while his parents were in the next room. I felt like I couldn’t say no",

  "I am disabled. In the middle of a severe pain attack, he gave me an overdose of my meds. I was confused, on the verge of fainting. He raped me. I said no many times, couldn't defend myself. He went on",

  "He held me down after saying stop 3 times. I was screaming and crying when he said this is God's will that he'd be forgiven after he prayed. I was 14 he was 18",

  "My brother snuck into my room for years starting when I was 10 years old and repeatedly r@ped me. It carried on for 6 years until I finally was able to call the police for help.",

  "After threatening to kill me, he made me stay at his house. He asked for sex but I said no so he pinned me down and dry humped my leg until he finished",

  "I passed out drunk & woke up to him taking my clothes off but I kept slipping in & out of consciousness. Every time I opened my eyes I had more clothes off & started assaulting me",

  "I went to sleep at peers separate room after evening out and woke up to penetration. It haunts me as I don't know could have been done to me while I was asleep.",

  "I was 12 you we're 50.",

  "I was 10 and didn't even realise what was happening. He was 15. It was also pretty weird so if I told anyone, they wouldn't believe me",

  "It doesn’t matter when it happened or how long ago it happened, it still happened. Your story is STILL valid!!",

  "He was my best friend, we were 14.",

  "I was 5 years old, It was my dad. I only recently stopped blaming myself.",

  "I was 15 when my boyfriend tried to rape me twice. And when I said no he forcefully fingered me till I bled and fainted. I still feel filthy thinking about it",

  "It was the classic “have sex with me or I’ll leave you” story, but the coercion didn’t stop there. I lost my virginity at 14 when I didn’t want to. Coercion DOES NOT equal consent. No consent = rape.",

  "There was this boy named Finn who went to UoB (Bristol) And when I was a first year he got me really drunk then kicked my friend out of my Room and assaulted me. It was terrifying",

  "He wanted a BJ. I said no, my mouth was dry and I didn’t want to. He kept persuading me until I eventually gave in. I regret that.",

  "When I was 11, a girl touched me in a private area and kept rubbing my thighs in gym class. I tried to ask my teacher for help, but he ignored me. I felt helpless, scared, and unheard.",

  "I went on a date with a boy who seemed really sweet. It was of the first dates I’d been on since my breakup. He got me drunk and forced himself on me even though I said no. I repeatedly said no.",

  "I was assaulted by my then-boyfriend. When I came out with my story, I was outcasted by my school for being a liar because I am a gay man, and I was called a “whore” or “slut.”",

  "I don’t think I’ll ever recover from the trauma that I’ve been through. It still affects me and I question everything & everyone. It’s been 11yrs",

  "It’s been nearly 11 years since I first got raped by then boyfriend. He told me in the morning it doesn’t count as I was his girlfriend. He done it again a few months later. No one believed me.",

  "he was my boyfriend at the time. he never asked if i wanted to, just when and where. i always said yes because he’d get mad otherwise. i wish i could get my v card back",

  "My boyfriend of over a year and I had a healthy sex life. One day we had sex he forced himself on me anally. I’d said stop and it took him like 1min before he did. Is this rape",

  "A courier delivery guy groped my chest while I signed the delivery form although I repeatedly told him to stop. I was 8.",

  "When I was a little girl I was sexually assaulted via truth or dare. I refuse to see it for how it actually is — sexual assault. It just doesn’t feel serious enough.",

  "I was raped by my cousin back when i was 16. Mental illness and depression was his excuse of forcing himself on me. I am 20 now and I am disgusted by mentally ill men.",

  "I was raped at 8 by my brother but none of my family members believed me. So I didn't report it, thinking if no one will believe me. He assaulted many girls after that. I blame myself a lil.",

  "My first ever boyfriend repeatedly raped and abused me. The final time he did I called the police. He got away with it all. 8 years later I'm still trying to heal.",

  "My ex often used me and assualted me and I continued to stay with him for 3 years because I was afraid to be alone. We broke up because he didnt like me or my body anymore, its been a year since.",

  "When I was 13 a boy I knew took advantage of me and sexually assaulted me and threatened to send the pictures and make false accusations towards me if I didn't date him.",

  "It was a week after my 16th birthday. I had called a male friend to come during a moment of vulnerability because my brother was drunk and screaming. He ended up taking me to a ditch and assaulting me",

  "When I was 11, I was groomed online by a 19 year old. I still struggle to look at myself in a mirror and not remember the pictures he made me send.",

  "I was new to the school, an immigrant from another country. He took advantage of me being excited about making new friends, so when I hung out with him, he and his dad kidnapped me and assaulted me.",

  "he was my friend he forced me to have sex. he kept going even though i said no many times",

  "Winchester university is in a crisis of assaults, spikings and rapes and the uni has done nothing. If you have the chance. Please come to Winchester.",

  "He was my “boyfriend” and he pinned me against a brick wall and assaulted me. I was told it was ok or just rough as we were together. I was 14. He was 18.",

  "After I opened up about how he assualted me for weeks straight. He started harassing me. I got away only to find out his best friend molested a young friend. They are in groups, harming innocents.",

  "I went to his party. He was my friend’s boyfriend. I slept in my tent in his garden. I woke to him sexually assaulting me. Why didn’t he sleep in his own bed, in his house? Why didn’t she believe me?",

  "We were friends. I thought I was safe. now I hope you think you’re safe.",

  "growth sucks and it’s tough but you are strong and u gonna get through it",

  "I see everyone growing after, THANK YOU FOR BUILDTIMG THIS ORGANIZATION!!!",

  "He masturbated while watching his friend rape me. Instead of stopping the situation, he got turned on by it.",

  "I was constantly having flashbacks where i could feel him on top of me. I was sectioned because i had tried to end my life because i was still being haunted by him everyday. I was trapped.",

  "I was in primary school and i had a “boyfriend” and he had taken me to where we would hang up our coats and he touched me in my private area and i had to touch him back. I felt confused and scared",

  "The first time i went to his house and he forced himself on me and he raped me. I thought it was a normal thing because thats what boyfriends & girlfriends do, then the second time it was at a park.",

  "A boy touch me in 5th grade I told the teacher and 7 other girls came forward he touch every girl in class they just said “be nice to boys he just likes you” my dad said “so what you want me to do”",

  "your abuser over you doesn't make your rape any less valid, and you shouldn’t suffer for somebody else’s actions.",

  "I told him no. I was so clear but he just wouldnt stop touching me, or asking. Again and again. I didnt want to have sex those nights.",

  "He pressured me into saying yes and now I am afraid of him. He’s in the same building as me",

  "I was seeing this guy casually as a rebound after a breakup. He kept pouring me wine and telling me to drink when I didn’t want to, and if I didn’t drink he’d lock the door until I did drink. When I was drunk enough to nearly pass out, he raped me. All so he could finish inside me when I told him no. Even if it was nearly 2 years ago, I can’t stop thinking about it. It still gives me nightmares.",

  "I blamed myself despite repeatedly saying no and biting the hand that held me down so hard he bled - ten years on and I still live with the ripples of what that night did to me",

  "I was 15, my boyfriend at that time used to makeout with me forcefully while I screamed and I used to bleed all over his hand through it all. He also attempted to rape me twice and made videos of me that he blackmailed to post online. I felt so helpless. I faced this for 6 months before I could leave.",

  "I had to comfort him after it happened … because he felt bad",

  "I wasn't ready, yet she was ready. She decided whether I was ready or not, and that's not fair.",

  "My boyfriend of two years raped me multiple times in my sleep I thought it was ok because being in a relationship meant you couldn’t say no Education is so important",

  "When I was a kid I was raped by my parents close person friend more than once. When I finally reported it at 3 years later nobody believed me. Until he did it to someone else.",

  "I stayed because I thought he’d get better, I thought he’d change once he got over the withdrawals. He used me for a week straight until I finally had the courage to leave while he was sleeping",

  "I was 15 and he cried and I comforted him afterwards",

  "Thought I would never have to see him again and then he transferred into my course",

  "We were married. I told him he was raping me repeatedly for years. He said it was more unfair for him to go without sex. He’s just been charged by the cps.",

  "Please consider supplying kits to overseas military bases. I was raped on a NATO base overseas - and the local healthcare was horrific and the forensics weakened the case when it came to the trail. This kit could have seen a predator punished to the fullest extent.",

  "She was younger than me..a kid,i was older And i let her do it my own parent ignored it and downplayed it I never told anyone about it.",

  "I only agreed to sex if he'd wear a condom. He took it off when I got up to piss and didn’t tell me. When I found out he got physically violent and forced himself into me again. He then made a joke about me not being wet enough and being too tight as a result of it. He was also physically violent and possessive the entire time in a way that made me terrified for my life. My flatmates heard and didn’t help me.",

  "My friends left me alone in a club when I could hardly stand with a guy known for drugging and sexually assaulting women. And then refused to speak to me when I went home with him even though I refused to come inside his house until he ordered the Uber to my house. He'd spent the entire bus journey home from the club sexually assaulting me even though there was a guy right behind us and it was obvious I was terrified and I didn’t reciprocate in the slightest. He still raped me inside his house with his parents home and then forced himself into my Uber home. I was terrified he'd try and get into my house to rape me again.",

  "I was 10 years old in my blanket fort. My dad wanted to show me what lesbians do. I still can’t be touched in certain ways 8 years later.",

  "It's been about ten years since my rape. It took a long time - years of therapy and sobriety - but I've finally found some peace in my life. My brain was really loud for a long time, and now I feel like I'm free from some weird prison I was in. I'm happy. You know what's crazy, though? Even ten years later, I think about it all the time. It doesn't sting like it used to, but it's a part of me. I panic thinking about being intimate with other people. I have a crippling fear of dating, even though I'm quite lonely. He probably never thinks about that night. But I do. I think about the blood, and the screaming, and the sock shoved in my mouth, and I fall asleep marveling at the power one moment can have over a person's life.",

  "I was in a relationship with you for three years. Two of them you made me feel crazy. You told me you “didn’t remember”, you told my sister “maybe I did it”. I put you first every time, when we broke up because of what YOU did to ME, I told you to get support from my family, all whilst I was suicidal for months without an inch of support. I’ll never let another man take advantage of my vulnerability and nievity like you did again. You broke me Khyl.",

  "He bonded with me over our shared experiences of sexual assault - he then did the same to me months later. That hurt more than the initial assault. He took my pain and doubled it, all in the name of 10 minutes of gratification",

  "He was my first proper boyfriend. I was 15 and he forced me to do it in the local park with a sweet wrapper, 10 metres away from a kid's football match. He continued to sa me for the almost three years we were together because he convinced me it was normal.",

  "I was raped by my boyfriend. He acted like it didn’t happen, and that he was entitled to my body. I will never forget the feeling of being outside of my own body, like it was no longer mine.",

  "When I was 7 my dad tried to teach me to say “no” by putting his parts on me and touching my parts, then when I turned 11 my cousin would touch me all the way up till I was 17 but because I didn’t want my family to live out on the street since we had just moved and had no money I couldn’t say anything. I would be the only one in trouble since he was an only child.",

  "the first day of the horrifying month. i had gone to my friend's house for a party with my bestfriend, and we had gotten drunk, i feel asleep but when I got up to throw up out the window, my boyfriend's friend came from behind and forced me to touch his penis and give him a handjob. i tried my best to shout and get away but i was too drunk to do anything. few months later now, my boyfriend is still in contact with him, and all my friends of that time took his side.",

  "The guy who raped me 6 months ago texted me and said I broke his heart… I don’t think he understands how much I struggle still to this day",

  "Persistently asking for sex until i reluctantly oblige is rape. I am so emotionally exhausted and i don’t want to put up with your gaslighting about how you “need” sex several times every day.",

  "My abusive ex did it twice to me. I took drugs recreationally, thinking I'd be fine. When it got to a point where I was unable to move my body or speak, he did it. I didnt understand what it was until months after I left him.",

  "He was my 50 year old tutor and I was 16 years old who didn’t know anything. It wasn’t my fault",

  "After 1 year of enduring it. Every time I would get in any argument with my mom; she would say “its good that you got SAed",

  "i was 13 (male) and he was 67, since he have mental illness he won't even spent a day it happens 3 years ago and he's still driving in my zone without the police doing anything, ( I'm Italian so maybe the laws are a bit differt)",

  "I am so grateful that I am one of the lucky ones and my perpetrator was found guilty. He abused me for almost 10 years and only served less than 4 incarcerated. I hate that I feel grateful because all survivors should get their guilty verdict",

  "I’ve been raped and assaulted so many times that none of it feels real. I feel different men’s hands all over me at different times every month, and that’s how I know it really was real. Sometimes I see the men and it feels like the attacks are happening all over again even though I’m “safe”. I haven’t ever felt safe I think. I don’t think I’ll ever feel safe.",

  "It wasn’t rape, but I was assaulted while unconscious and woke up to him ejaculating onto me. I was told to get over it, ‘it’s not like he raped me’, but I still feel disgusting over a year later.",

  "i got drugged at a party i went to. he took me home to “take care of me,” turns out he was the one who drugged me after i told him i didn’t want to sleep with him that night. he chose to drug me to get what he wanted out of me.",

  "I was raped at the end of a night out, a guy from my accom tracked my location and came to my room when I got back. He was sober, I could barely walk in a straight line",

  "When i was 8. I was at the waterpark. Im grateful i wasn't raped. I was in the deeper section of the wavepool. A guy grabbed me from behind. Grabbed my breasts. And touched my back weirdly and left. I was 8. I never told anyone. I was sick for 4 days because of flashbacks and vomiting but i just told my parents it was food poisoning. I am so thankful i wasn't raped. It was terrifying. If i was raped, i would rather choose d3ath.",

  "It’s been five years now since the last time he touched me… I still struggle with it on a daily. My fiancé can’t even touch me some days because my body is still in fight or flight and I feel awful…",

  "No one prepares you for that feeling of wanting to crawl out your skin or just pure disgust at your own body. Exercise went out the window for a long time. Slowly, i've started doing half an hour walks everyday. I've found this incredibly healing. Don't loose hope. You've got this. Take small steps where you can.",

  "I was raped three years ago in my first year of uni. My memories of that night are hazy and fragmented, coming and going unpredictably - less often now - but still, sometimes, it feels like I'm getting closer to remembering it clearly. For a long time, I doubted what happened to me because the memories didn't fit together perfectly, like pieces of a puzzle. But that's not how abuse works. It did happen. My mind may forget, but my body never will.",

  "I was 9 when I met him. I was12 when he started. He was my step father. At 14 I stood up for myself, and he is now in prison. No one that young should ever have to endure the terrors of this world.",

  "I’m 23 and only JUST started remembering being sexually abused by my dad and brother as a child. It baffles me that my brain could repress it for so long.",

  "I was raped three years ago when I was in my first year of uni here. My memories of that night are hazy and fragmented, coming and going unpredictably - less often now - but still, sometimes, it feels like I'm getting closer to remembering it clearly. For a long time, I doubted what happened to me because the memories didn't fit together perfectly, like pieces of a puzzle. But that's not how abuse works. It did happen. My mind may forget, but my body never will. Sending all my love to other survivors.",

  "I hate him.i hate him. I hate him.",

  "It was my first time. Im a little scared to experience a proper time because of it",

  'I was wearing revealing clothes and dating him and "led him on" still feel like its my fault.',

  "I was in a poly relationship with him and my current bf. He lied about being mtf to make us feel safe, and then touched and raped me in front of my bf (both of us were significantly weaker and smaller than him) sometimes i feel like it didnt count because he eventually stopped",

  "After the man I trusted assaulted me he asked me if I was having flashbacks because I was crying, he knew I had been assaulted in my childhood, he told me I liked being assaulted by a family member… he then assaulted me again. He was in his 50s I was 16. In my state that’s legal…",

  "I told him to stop because I was in pain. He told me he needed 30 more seconds and held a vibrator on me. I left my body that time. The police never talked to him.",

  "“It’s only rape if you think it is” - my high school boyfriend of eight months.",

  "I was passed out a college frat party, apparently my limp body was a yes",

  "He was my cousin. I wasn’t the first one and probably not the last.",

  "I knew him from school. He told me his parents kicked him out for being gay so I said he could stay on my sofa for a couple days until he figured something out. Turns out he wasn't gay and he raped me. I was so scared, then he moved in with me without asking my permission and raped me almost every night for a year he also didnt give me a penny for rent or bills even though I was really struggling financially. In the end, he left me because my mental health got so bad I stopped eating and fainted at work. He also said I was too old for him, I was 20 at the time. That was 3 years ago now, I still have nightmares.",

  "I thought we were in love so I didn’t want to accept that he had disrespected me and my body.",

  "I didn’t realise that someone having sex with you while you are sleep isn’t normal. It was only after talking about my experiences that I realised how I should have talked sooner but it can feel so confusing when you don't fully understand what happened to you because you weren’t conscious.",

  "Puppy love can turn into lust. I was 12.",

  "My boyfriend at the time did not take no for an answer, as if he had the right to use my body whenever he liked. I never owed him. I will never owe anyone.",

  "We had only been dating for maybe a month the first time. it happened throughout the 3 months I stayed. I went to my school and they continue to do nothing. My health teacher (who is in charge of teaching consent) told me that I \"shouldn't use big words\". it's been two years and they still don't separate us",

  "About this time lst year i had a girlfriend and she got me high and dragged me to her room and assaulted me. I was helpless and it feels like no one believes me",

  "A bouncer who was known for protecting drunk and vulnerable women cornered me in the club toilets and assaulted me and after reporting it I was targeted by his male and female friends physically and verbally for “lying”.",

  "When something so precious as your first sexual experiences get taken away from you, it causes such long term suffering. May precautions and initiatives grow to prevent this.",

  "i was raped by my teenaged uncle at age 7. when i came forward a few years ago my parents got the police involved, which led me to do a few interviews with a detective. after the interview was over, the detective told my mom i was lying and that there’s no way to convict even if i was telling the truth and stopped working on my case until it had to be reassigned to a new detective. fine if there’s no way to convict, but why in the world would you tell a 16 year old girl who got the courage to say something she lied.",

  "It happened over and over again. Asleep, awake any waking moment he got the chance. Even in front of others…it got to the point it gave me health isssues and I had a miscarriage. It’s been 5 years since I left him. I still struggle some days",

  "When I was 17 I stayed at my friends house for a party. Woke up that night to a boy I had only just met fingering me. He got into my bed while I was asleep and did that. I ran out the room and went downstairs. Found out in the morning he had done it to one of my other friends after me. In our sleep and I didn’t know him!!!",

  "Male to male rape is not spoken up about enough - I didn’t even identify it as rape",

  "Mine was my best friend a couple years ago, I was basically asleep but she wouldn’t let me not do anything without making me feel bad",

  "It was the first tinder date I ever went on. I feel guilty because I didn’t shout, I didn’t fight, I didn’t scream - I asked him to stop and when he didn’t, I shut down. After, he told me I better set an alarm for the morning, because he wanted to do it again before I left - I cannot describe how I felt in that moment.",

  "I was 10 and my p.e. teacher manipulated me to go to the school abandoned zone where no one would hear me scream...i got pregnant and during the pregnancy 3 months later he did it again but more brutally so i lost the kid on the school bathroom... Only my parter knows until this day...",

  "I was 4 and my father did it for a whole month to me and my half sister, while all our half brothers of 15, 14, 17 and 13 ignored it or laughed",

  "I shared a tent with a guy from school who then proceeded to try and force his d*ck in my mouth whilst my hands covered it to stop him. Eventually he gave up, called me a bitch and went to sleep. Could have been worse so counted myself lucky.",

  "It happened after what had been a lovely first date. He switched as soon as we got back to his place for drinks. His smile faded and it was like looking into the eyes of a monster. He pushed me onto the bed, pinned me down and kept going through my tears and begging him to stop. I still can’t comprehend how anyone could be so evil.",

  'It was my second day of gr7 at a new school. That day, a boy sexually assaulted me in class then raped and beat me in the bathroom for over 3 hours. I later found out I was pregnant at 12 years old. When i told the principal, she said "thats just what boys do"',

  "It happened when I was really young. It was my younger brother. He put his hand between my legs. I was wearing a dress. I grew up hating wearing dresses.",

  "It was my little brother. (Not full 🍇 but SA while I was asleep) He was much younger than me, so I know he couldn’t have fully grasped what he was doing or how it would impact me the rest of my life. I also know that in order for someone so young to know anything about touching someone like that, it must’ve happened to him too. COCSA is so confusing and deep. I don’t even think he remembers what he did to me, but it’s a secret I have to carry to my grave.",

  "I was sexually assaulted by my cousin. I remember blaming myself for it. Thinking there was something wrong with me. But I was just a child. I was only 8",

  "it all happened when dad came home highly drunk slapped mom 3 times and came towards me angrily as i shouted on him and was about to slap me but stopped cuz mom came in between ... he never assaulted me ... but the fear that he can do it because a drunken men doesn't know what he can do... i feel unsafe at night he is a nice dad but not a nice husband or human what if something happens one day.. why being a girl i have to doubt even my father..i still don't say anything that hits his ego at night .. cuz what if he is drunk cuz as always a drunk man can do anything!! maybe one day i will escape it and make a new home .",

  "He got away with multiple counts of r*p* and d*mestic violence, because 'he has autism he doesn't know what he's doing\". But he did? He planned it out. He planned out how to ruin my life at the age of 15.",

  "Drugged and assaulted by my sister’s partner when I stayed at her house. Apparently there’s not a chance of prosecution. Destroyed my trust and relationship with my sister. Now having therapy for flashbacks and ptsd which has to be paid for by me. Struggling to maintain work due to the impact of this. I wish there was a place to go and recover and restore. Where is the accountability and punishment in this country? My ISVA was lovely but the legal system seems more likely to traumatise me than hold him to account.",

  "It as my best friend of two years. We had a sleep over and she made me do stuff I didn’t want do. I didn’t think I was sexual assault because I never said no. I blamed myself for so long.",

  "Every instance of being harassed or sexually assaulted has been while I was a child. It’s happened a lot but the worst was by the man my mother married (not my father). It happened repeatedly over a few years before anyone found out. I was younger than 10.",

  "He told me no one would believe me",

  "I was 13 and in my first relationship. He thought it was his right to be able to do whatever he wanted.",

  "this is for the young 14 y/o me. no u didn’t have to say “no” more times. no u didn’t act “not so rejecting” to his acts. u were just a child",

  "i consented to sexual things beforehand, but not as far as he took it. even though i said stop so many times, i felt like it was my fault for so long. i’m married to someone else now, but it still haunts me.",

  "He was my long term boyfriend who I lived with. One morning he asked to have sex , I told him no I’d rather not as I didn’t feel like it. He asked if I wanted to have a back massage instead which I agreed to. Whilst my back was turned, he put his penis inside me without my consent. Only 5 minutes after me telling him I didn’t want to have sex. I tried to move away from it but he was knelt on the back of my legs so I couldn’t move. My body went dead as I realise what he had just done.",

  "It was a tight space, I could move and I just zoned out until I realised it was over. All of a sudden, I'm a cheater and a liar and my story isn't good enough for the police to do anything about it",

  "People get mad at me for how i define what happened to me. My sister molested me and since she doesn't have a penis it isn't \"technically\" rape. I've had people get upset with me for calling it rape.",

  "I was 7 and she disguised it as playing. The guilt weighed on my chest like stone",

  "I was 9, he was 12-14 (I can't really remember how old he was). We were in his bedroom and he was touching himself and tried to get me to perform him 0ral s3x. I couldn't understand what was happening but it felt wrong because he was telling me that I had to keep it a secret and I said no. Then he took me to the bathroom and asked me again. He didn't touch me, but this memory remained dissasociated from my mind until I remembered years ago and I can't stop thinking about it and crying. I was just a little girl, I didn't know what was happening.",

  "No idea who he was. Or his friends who also followed me into that bathroom. I wish this was a thing then, would have been insanely useful as I was still a teenager.",

  "It happened to me when I was 18 and too scared to call it rape. It shaped my relationships with men for the following 10 years. I used to hate myself for not reporting it, but I know now how hard it is to do. If I could have had one of these kits back then, I could have had a very different experience in my twenties.",

  "The sexual abuse happened when I was a child by a family member. Even though it happened over 10 years ago every time I go to sleep I’m back in the room suffering from the abuse. I’ve only just spoken up about it so it feels so fresh and the anxiety is real. I wish the authorities would do something but they are considering his age but he never considered my age when he was abusing me.",

  "I set up a date with someone I met through a dating app. I got spiked and assaulted but was too scared to tell my parents because I met the person online and felt like it was my own fault.",

  "I wasn't sure that it even counted as he is my boyfriend and thought I was playing 'hard to get' apparently",

  "My coworker rapped me in the back stairwell of my apartment it has taken a month for me to finally tell my manager",

  "I was raped at 13 years old by my younger cousin. I was also raped at 19 years old by my ex. Nothing will ever take away what happened to me. But we may be able to help other people. No matter the age, gender, sexuality, colour, everyone should have access to this because rape happens to every single type of person. As soon as I have money I will buying kits for my son, my daughter, my next door neighbours children. And as often as I can I will be buying kits and talking about this to everyone who will listen. This could change lives. I only wish this existed when I was younger.",

  "It was my boyfriend at the time, he kept saying he needed to and it hurt him if he didnt do it with me, i kept saying no until i said yes, i didnt know what it was until now. I still havent told anyone except for 3 people",

  "I was 17 and a virgin and he was 24. I was so scared, literally heart pumping out of my chest but he wouldn’t stop and I felt so small and just froze. I could never say anything though because no one would believe me and defend him",

  "I said no. He heard me, laughed, and continued anyway.",

  "I was 16 and I was completely blacked out from alcohol for the whole year and multiple guys took advantage of that.",

  "In Halloween 2022 a girl in our friend group was walking behind me at night, she then started touching me non-consensually and eventually used her fingers to penetrate and no one helped despite others being aware of the situation.",

  "He was my best friend. All i wanted was support and a friend. He took me for granted and assaulted me because i was an easy target.",

  "An ex boyfriend of mine sexually assaulted me during sex. Any time we did anything after that incident, I would just wish for it to be over quickly so I could go on about my night. I didn’t realize then what it was until I was an adult and I struggle with it everyday since.",

  "I’m so sick of people invalidating my assault because it was a female on female assault. She shouldn’t have touched me in my private areas. I was just trying to do my school work. It feels like I’ll never be ok again. And I’m sick of getting told I’m lying.",

  "When I was 12 I was playing hide and go seek at my “boyfriend” house at the time the person who was finding us was a very close friend of mines. Next thing you know he locked me in the bathroom with him and put his hand down my pants I pushed him off of me and screamed and ran out of the house and never spoke to him again and never told anyone out of fear of being called a liar or them thinking it was my fault.",

  "Back in 2020 I was raped by a boy, I was 19 years old at the time and I had just recently lost my virginity. After he raped me i went to the bathroom to cry and when i came out he asked me why i was crying(i dont think he knew what he had just done ,even though i said no multiple times) I was going through some very conflicting thoughts about myself that I still don’t really understand. I kept going back to hang out with him and have sex with him. I think it was because I wanted to be in control in some way or because I didn’t want to see myself as a victim. I’m still not really sure why to this day. I’m still so upset at myself for not reporting him. After a month or two when I realized what I was doing to myself by going back to him I did cut all ties with him and all our mutual friends.",

  "It was Halloween 2022 and a girl in our friend group was behind me. She began touching me and eventually used her hands to penetrate. No one came to help even though others saw it. I hope no one has to go through this again.",

  "When I was 14 I entered a relationship, little did I know this man would soon start threating me to have sex with him by cutting himself and threating to kill himself in front of me, for the next 5 years I couldnt escape it, he told me if i left him he'd kill himself and it would be my fault. I was a kid, i didnt understand that i had options. I wish i had this kit back then. I broke up with him and left the state so he couldn't find me.",

  "He was my best friend of over 10 years walking me home. Someone I trusted. He asked for my partners consent but not my own. They were both people I trusted.",

  "My best friend of two years made me cut off all friends. I was really struggling and they made me feel like they were the only person that loved me. Then one night I stayed over and she slowly crept into my bed and I didnt have the guts to tell anyone for two years.",

  "I hate how he made me feel guilty about it when I tried to reach out for help. It is not your fault, you are not alone. It is ok to reach out for help",

  "I was 12 when I was raped by a bus driver and got bullied for getting him fired… after that I was raped by an ex boyfriend in front on my 8 year old sister…",

  "I thought this was going to be some sort of threesome with a guy and a girl I met on a night out. None of us knew each others. Me and the girl were drunk. The guy wasn't. It ended up with the guy raping the girl. When I realized what was happening, I froze. The guy left. The girl didn't want to report it as she said she was too scared to go to the police, even with me. We spent the next 3 hours in shock and went our own ways. She cried a lot. We wrote to eachother once a year later and never saw each others again. I have been thinking about this every day for the past 9 years and will continue for the rest of my life. This happened in New Orleans in 2016.",

  "It happened last year July, I thought it was okay since I was dating him. I still remember how it all happened. Sometimes I wonder, Did I do something? Even though I didn’t. It just haunts me.",

  "when i was 11 or 12, my moms cousin came to visit. this was around covid, so i may have even been younger. her son, probably 15 at the time? had wanted to play mario kart in the switch i had in my room i had saved up for. i don’t normally close my door, but he did and i didn’t think much of it. we were sitting on the edge of the bed, and he just kept repeating we’re not really related, yeah? and i just for this bitter feeling and said i was thirsty. i left him in my room. i don’t know if he did anything but to this day i wonder if he leaned a bit closer, if i didn’t leave.",

  "I was 11. I thought she was my best friend, but she still ruined me.",

  "Opening up about my experiences with sexual assault was so difficult. But opening up to a close male friend who took advantage because I was ‘ used to it’ broke me",

  "It was never your fault. You matter, your feelings matter, your voice matters! There are people who will hear you and who will believe you. You're not alone x",

  "I was told i deserved it as a bigger girl and the police seemed to share that view point, invalidating the worst thing that ever happened to me. No person should have to be more isolated than SA already makes them. Predators can chose anyone, age, size or gender and it is as valid in every case",

  "My ex raped me but I didn’t know at the time. I said no and when he did it anyway, I thought maybe he didn’t hear the multiple no’s that I said. I’ve told a couple people but some of them didn’t believe me.",

  "My boyfriend at the time raped me on a date. I told him no but when he did anyways my body froze up. We broke up less than a week later and he told everyone I was raped when I was young. I still have to see him constantly but I’m scared to say anything.",

  "I still feel dirty 2 years on.",

  "EMDR changed my life for surviving the trauma of rape.",

  "I was raped by a fellow NHS worker, I never got justice. I kept quiet for 2 weeks and then told my family and the police. It never went to court, he still works at the hospital and so do I. Everyday hurts and I’m terrified to go to work.",

  "I wish you would feel the hurt and pain that you make me feel everyday.",

  "I went on a date, he got me drunk and raped me at his hotel room. I couldn’t remember what happened for a few days, I still feel like it’s my fault.",

  "It happened twice in my sleep. Not rape but sexual assault - apparently I was acting like I wanted it while f*cking asleep. You’re meant to be safe when you sleep next to someone you love",

  "I was a child when it started, about 6 years old. At about 12-13 is when i managed to escape him and every day i’m thankful that i had the strength for that so young.",

  "I felt paralyzed and couldn’t breathe, 3 years later and I feel the exact same way every night. The nightmares never end.",

  "It was my bestfriend one minute we were laughing and the next I was on the bed hysterically crying and begging him no. Afterwards he proceeded to tell me it wasn’t rape and that I was delusional if I thought anyone was going to believe me",

  "It happened a year ago and I hate that it’s changed my life completely- I don’t enjoy the same things and I’ve lost my confidence in every part of my life. Slowly building it back though.",

  "I was 15 years old when I was raped, it was the most traumatising experience. An ex partner of mine. None of my friends believed me, it took me a whole year to tell my parents, I was petrified: I wish this was easier to take about with people",

  "He didn’t stop when I asked him to . Consent can be withdrawn at anytime never forget that ❤️",

  "I was 16 and he was my first boyfriend. He’d spent weeks telling me how “frigid” I was. In the end he locked my bedroom door and told me if I didn’t do it he’d break up with me. The pressure I felt was wild. I’m jealous of people who had a good first experience. I felt so dirty and embarrassed.",

  "I went to a work friends house for the first time and he took it too far, I was too scared to get a kit done and I fell pregnant because of it",

  "Because we were in a relationship and I was a man I thought I wasn’t allowed to say no. I always felt pressured and never felt like I had a way out.",

  "I wish i had this 5 years ago... nobody believed me because i was too scared to speak up ab it when it happened.",

  "Even though it’s been 4 years since it happened it still causes me a lot of suffering and mental pain and unwanted panic attacks anxiety and depression but I don’t know if I would of felt differently if I reported it and if I would struggle as much as I do now",

  "I was failed by the police when I reported it they said I basically asked for it and consented when I didn’t I wish people like me would stop being failed by a corrupt government who don’t care",

  "He was supposed to be a friend, I told him I didn't want anything more than just being friends. He drugged me and raped me.",

  "I have been raped multiple times throughout my teen years by boys much older than me I considered friends. I thought it was normal. I then met 2 men for a party when o was 20. They drove me to the middle of no where and said they thought I was going to “brick it” at which point I clicked. I went along with it and allowed them both to brutally rape me because I didn’t know if they’d let me live or how I’d make it home if I got away.",

  "He was my longest relationship. He sexually abused me from the 3rd date, threatened me with his knife, videoed me without my knowledge and so much more. He raped me a minimum of 3 times but it took me crying and begging him to stop in a dark alleyway to realise that it's still rape even if he is your boyfriend. It's wrong no matter who it is.",

  "I hope people, like me, who have been assaulted remember there is no shame in survival. You were NEVER to blame",

  "The first thing I was asked by the police was \"why were you out that late?\" I was already living in a refuge after a lifetime of abuse from men. I was finally safe and went out for the same walk around the block daily. I was made to believe that because i was out after 10pm, wearing shorts, that it made it ok to be raped by an unknown male. I didnt know any different because this happened to me my whole life. I know now that this was not ok. Wearing 'revealing' clothing is not consent, walking home late does not take away my right to say no. Saying nothing is not a yes. Rape is the only crime where the victim becomes the accused.",

  "I was 15 and he was my first partner. I asked him not to do it. I told him I’m in pain. I asked him to stop. After everything he said that he couldn’t control himself cause “I look too good”. I’ve realized it was SA years later on the therapy.",

  "he was my friend, and I couldn’t say no to him because we were stuck in the woods together, who would have heard me?",

  "I was made to feel it was me who was in the wrong for not liking the things he wanted to do. My no wasnt even concidered and was even more of a challenge. I am now left wondering who I even am anymore.",

  "I walked them home to make sure she got home safe. In return they forced me into having sex and took my virginity",

  "I carried the burden of my r@pe for 10 years. This year I shared it and now I get to move forwards.",

  "He was my best friend and I trusted him. We had platonically shared the bed several times after nights out before. But this time he put his hand round my throat, slapped me across the face and did whatever he wanted. I have since confronted him about it.... I hope he feels ashamed of his actions, I don't feel any shame on my part. I will keep my power, use my voice and help keep others safe and strong.",

  "It was my girlfriend. Just because you’re dating does not make it okay. Women can do it too. I wish more people would understand that.",

  "I was 15 and it was my first date. He got me drunk and took advantage. It took years to process what had actually happened and i know no that it’s still okay to not be fully healed from what happened",

  "I spent so long blaming myself- maybe I was too drunk, maybe I asked for it. Now I realise being asleep is not consent and it was never my fault. I wish I knew this sooner.",

  "He insisted on getting in my taxi home. I kept saying no. He made the driver cancel his stop. He got out at mine and insisted on coming inside. I didn’t want my family to hear. He wouldn’t take no for an answer. It happened in an alleyway 20m from my home.",

  "I endured SA from the age of 16 for 8 years. The only thing that made me realise it wasn’t normal was when I had a miscarriage from a fight where he stomped on my belly until I bled. I don’t feel like a normal person, I am still deprived of pleasure and I want to give up a lot but living for others. I struggle and I can’t contain any close relationships",

  "I hope you understand the damage you have done and I’m stronger than that damage and I will take my experiences to keep people safe",

  "I was assaulted in a club, spiked and raped. I'm still scared that every time I go out I'll see him again, yet I go back to the same club every week. I feel crazy and stupid",

  "I lived on frenchay campus in first year. I had three rapsits in freshers, and then met my abuser who lived in the court opposite me. I was raped hundreds of times but I thought it was love. Noone cared even though they knew, because him being their drug dealer was more important than integrity. I went into lectures with bruises on my face but not even my lecturers asked if I was ok.",

  "When I finally got the courage to tell people what had happened, he tried to convince me that it was my fault and that it would ruin his reputation. I was so scared, I told our friends I must have gotten things confused. They treated me as a liar and alienated me.",

  "I was raped by my partner, i doubt he even knows or cares what he did. I was asleep in his bed.",

  "The guilt of not knowing its its my fault, as a male by a male, not wanting to speak up incase it ruins his life over something he may not have understood the severity of",

  "It was easy to let him carry on. My family were home and it was the middle of the night. He was getting annoyed whenever I said stop, I didn’t want to wake anyone. He was a family friend.",

  "I think it was partly my fault I mean i was dumb enough to trust him, to go to his place at 2am, to drink what he gave me",

  "Even though I verbally and physically resisted and he violently proceeded to rape me, I still felt hesitant to tell anyone afterwards, because I had had something to drink that evening and I expected this would influence people to blame me for it (I blamed myself as well).",

  "I always said no but it was never enough",

  "It happened all throughout my relationship but I couldn’t tell anyone as I thought it was normal. It’s not",

  "\"Your beautiful that's why\". That wasn't a compliment that was a threat, an excuse for his actions. He explained it away as a \"man's need\", something he couldn't control.",

  "People believe you have to be evil in order to assault, when in reality, all that has to happen is a misinterpretation in words. Regardless of intent, when consent is ignored or not prioritized, the effects are just as traumatic as an assault with intent to hurt. Check in on how your partner is feeling, make safe words, and always ask when unsure, you cannot mindread.",

  "He was my best friend and although I never said no, I screamed and there was blood dripping onto the floor. It took me weeks until I spoke up and then he got let off with a warning from the uni while I was left with daily nightmares and anxiety. 🧡",

  "I am waiting for the day every cell in my body has regenerated and he has never touched the body he hurt.",

  "I was gang raped at 19 after my first night out - no one stopped it.",

  "I wish I would have told someone when it was happening. I feel so guilty. It’s so hard seeing him every day at school too.",

  "I found out my ex raped me, i didn’t know it was rape at the time. I had told him “While i’m in a sober mindset i really don’t wanna lose my virginity please don’t let us do anything even if i say yes later” He got me high, i couldn’t speak. Then it happened",

  "As someone who thought It would never happen to me and I’d be able to see the signs. I didn’t it was my boyfriend I was 13.",

  "It was Halloween had a bit to much too drink I thought we were all friends and we came to chill at mine. I only rember that I blacked out and that I was in bed my sister in her bed it was my first time and I don’t remember anything but just laying there, and they took me to the shower and I don’t know why it happened. No one ever told me anything and everyone acted like it was fine.",

  "To all of my brothers who struggle alone - you have got this. As a male victim myself, it has been a struggle to find others who have experiences like me. It does get better, speak out and don’t let yourself be silenced!",

  "There is not a day that goes by that I dont think about what happened. I can’t imagine how much worse it would’ve been if I agreed to go back to your dorm room. You still did so much damage even though our friends were asleep only a few feet away.",

  "Sometimes it’s your best friend who you thought cared so much more about you",

  "I didn’t even know it was rape until after. He insisted and I kept saying no and then I gave in because I liked him and didn’t want to upset him. I hated every second of it.",

  "I've never really spoken about it. A lot of my closest friends don't know. It is something that haunts me. I blame myself, even though I know it's not my fault. There's a feeling of shame. How could I let this happen. It was 2 of my dad's coworkers. They drugged me. They told me the next day how funny it was, and how they wished they recorded it.",

  "Last time it happened, I saw where it was going. I was out at a bar with a family friend, and a guy there wouldnt leave me alone no matter how many times I said no. Eventually I saw that if I didn't say yes, it'd happen with force anyway. So went to the bathroom with him just to get it over with and got blackout drunk right after to try and forget about it. It didn't help much.",

  "***** ******, 20 years old , Sunshine Coast Queensland. You raped me, enough is enough 🧡",

  "don’t ever feel like you’re not worthy of love because of it, you deserve healthy love and it’s not your fault",

  "I felt like I couldn’t come forward or report what had happened because the mutual friends who we met through are still friends with him and I didn’t want to lose them. If I’d known about this kit at the time I would have at least been more able to make that choice. Thank you for creating this❤️",

  "I was so scared, so shamed I let someone I knew do this to me and be frozen and not fight. If I said anything it would blow up both our lives but literally couldn’t sleep without nightmares . But after time I learnt to move on not forget and be more confident not to let someone do that again",

  "They won’t stop us by not believing us, because we as a community believe each other. And together we will make change, by raising our voices",

  "I kept saying no and he kept kissing me and I passed out. When I woke up he was assulting me . He drove me home home and told me not to tell anyone because he'd get battered and I'd get called a slag by everyone",

  "I can’t believe I tried to protect someone by hiding what he did to me when didn’t even care that he raped me, he didn’t care that he did it again. I wish I could go back and be angry in the moment. It took me years to be angry about it. Being ashamed, staying scared, and keeping quiet as the trauma developed into PTSD wasted so many years of my life. If something like that ever happens to anyone, I wish them to be able to be angry like a best friend would. Don’t let shame manifest for you, it’s the person who did it who should be ashamed. You're entitled to be angry at someone ignoring your boundaries and doing as they wish. Logically, you know it. So let yourself feel it.",

  "I hate that I felt sorry for him, sorry for telling people what he had done, when he was the one who felt no remorse",

  "I can’t believe I was scared of ruining his reputation in a world where a sexual predator can become president",

  "I’m unbreakable . He didn’t kill me that night but something inside me did , I grew from it and learned not to let anyone else touch me or hurt me",

  "Just because you don’t verbally say no doesn’t mean it’s okay, freezing and but saying yes is enough. Don’t let anyone invalidate your experience and tell you how to heal from your trauma, only you know the impact that it’s has on you.",

  "It doesnt matter who does or doesnt believe you. You know the truth, so speak it. It may help others who are hiding.",

  "He tried to turn me against myself, but I am stronger than his lies and manipulation. I know the truth and so does he.",

  "I keep telling myself I'm just being dramatic or that it didnt actually happen. Because he was whispering in my ear telling me it was ok and hed be done soon. I can still feel his hands on me. I need help.",

  "There will always be a black dot on my timeline. But that doesn't mean it can't be colourful again.",

  "He eventually decided to grab me and kiss me without my consent and touch me infront of my colleagues. I haven’t felt so weak and powerless in a long time, it felt like my whole world had come crashing down again. But he didn’t stop, even after being told to leave me alone and no countless times he persisted and persisted. I eventually broke down and reported to, it is now being dealt with and he is facing consequences. I don’t feel as ashamed anymore but that feeling of guilt and disgust of my body still lingers. I hope to soon not feel guilty for something HE did to ME.",

  "I told my bfs mum I was raped. She asked me about it the first time I went to his house and I did not want to speak of it. She thought I was lying, so I had to dig through screenshots and messages to try prove I wasn't lying. Ends up it was all for nothing, she didnt believe me still and he left me.",

  '"I know a sound is still a sound around no one". A Fiona apple quote that helped me when I had a highschools worth of people telling me I lied.',

  "When the memories hit, remember it's your skin not theirs. They may have used it but they can't take it.",

  "I’m most scared of myself: what if in my blacked out state I had given the impression that I wanted it? I’d be branded a home wrecker.",

  "I stayed silent about it in the groups but hearing others speak openly about it gave me my voice finally.",

  'Asleep is not a yes. "I just wanted to make you feel beautiful". Rape never makes anyone feel beautiful. It is terrifying and painful. It has been 9 months since what he did to me and I still can\'t sleep.',

  'We all deserve justice and we all deserve to heal. We never deserve what happened to us, but we will get our lives back. We will stand strong again. We are beautiful, handsome and outgoing people. We will be okay. And eventually good. What takes time to hurt, takes time to heal. "',

  "I found out and was scared to tell anyone in fear of how they would perceive me",

  "He was never arrested but hopefully it scared him enough to not do it to anyone else",

  "I don’t remember but i know something happened, everyone thinks I’m being dramatic but I know my body",

  "He is still walking free to this day.",

  "Coming to terms with what he did to me was the hardest thing I’ve ever had to do in my life. I couldn’t wish that pain on anyone. There is an after though, one in which I have never been more empowered in my bodily autonomy and sense of self and I wish that for all survivors of sexual violence.",

  "1 year ago I got ra**d. It’s still extremely hard to say the word or think of it however, I’ve come so far, I’ve managed to heal myself more and not think about it every day.",

  "Asleep is not consent. “I didn’t mean it like that” isn’t a valid excuse.",

  "Although reporting him didn’t get him caught, I felt a sense of relief knowing I could potentially help another victim if there should be one.",

  "It wasn’t a stranger in a dark alley. It wasn’t overtly violent like in the movies. It was a colleague who abused his power. This or freezing due to psychological threat does not mean your experience isn’t valid.",

  "People around me said “maybe you just didn’t remember” but you know your gut and your body and if something doesn’t look or feel right, chances are something wasn’t right. Lack of memory does not mean you are in the wrong.",

  "I’m not defined by what you did to me, or by how everyone else defined what you did to me.",

  "It may feel like a stain on your body but stains can be covered up and removed eventually. It may take more work but you can recover and reclaim your life back. It takes time, therapy, and support.",

  "Sometimes I wish he was dead not because of what he did to me but so I would know he couldn’t do it to anyone else",

  "I should not be ashamed. YOU should.",

  "Being in a relationship with them doesn’t make it okay.",

  "I hope he feels the pain one day he put me through. I hope he burns in hell.",

  "My fear was not your permission",

  "What you did to me was wrong in every sense of the word and I know that now",

  "Like Madame Pelicote said, shame needs to change sides. They knew what they were doing and isolated me to action their plan.",

  "I now know not to blame myself for what he did.",
];
