import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import { Stack, Typography } from "@mui/material";

interface FAQProps {
  title: string;
  children: React.ReactNode;
  full?: boolean;
  variant?: "subtitle1" | "subtitle2" | "h1" | "h2" | "h3" | "h4";
}

export default function FAQ(props: FAQProps) {
  const size = useContext(SizeContext);
  const large = size === "large";

  return (
    <Stack
      sx={{
        p: large ? 2 : 0.5,
        mb: large ? 1 : 3,
        mr: large ? 10 : 0,
        width: large ? (props.full ? "100%" : "25rem") : "100%",
      }}
    >
      <Typography
        variant={props.variant ? props.variant : "subtitle2"}
        sx={{ mb: large ? 4 : 1 }}
      >
        {props.title}
      </Typography>
      <>{props.children}</>
    </Stack>
  );
}
