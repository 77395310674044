import React, { useState, useContext } from "react";
import { SizeContext } from "./App";
import { Box, Typography, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Links } from "../types";
import ButtonLink from "./ButtonLink";
import ReportFormSection from "./ReportFormSection";
import WhatNextSection from "./WhatNextSection";
import PaddedBox from "./PaddedBox";
import PageContainer from "./PageContainer";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";

enum PageMode {
  Menu,
  Lite,
  Full,
}

export default function ReportPage() {
  // const urlParams = new URLSearchParams(window.location.search);
  // const anonymous = urlParams.get("anonymous");
  let initialMode = PageMode.Full;
  // switch (anonymous) {
  //   case "true":
  //     initialMode = PageMode.Lite;
  //     break;
  //   case "false":
  //     initialMode = PageMode.Full;
  //     break;
  //   default:
  //     initialMode = PageMode.Menu;
  // }

  const size = useContext(SizeContext);
  const large = size === "large";
  const [showMessage, setShowMessage] = useState(false);
  const [pageMode, setPageMode] = useState<PageMode>(initialMode);

  return (
    <PageContainer>
      {pageMode === PageMode.Menu && (
        <PaddedBox orange>
          <Stack
            direction={large ? "row" : "column"}
            justifyItems="center"
            justifyContent={large ? "space-evenly" : "center"}
            alignItems="center"
            gap={large ? 0 : "1em"}
          >
            <Box
              onClick={() => {
                setPageMode(PageMode.Lite);
              }}
              sx={{
                border: "solid 2px",
                borderRadius: "1em",
                padding: "1.5em",
                width: "14em",
                cursor: "pointer",
              }}
            >
              <Typography variant="h1">It happened a while ago</Typography>
            </Box>
            <Box
              onClick={() => {
                setPageMode(PageMode.Full);
              }}
              sx={{
                border: "solid 2px",
                borderRadius: "1em",
                padding: "1.5em",
                width: "14em",
                cursor: "pointer",
                ml: large ? 5 : 0,
              }}
            >
              <Typography variant="h1">It just happened</Typography>
            </Box>
          </Stack>
        </PaddedBox>
      )}
      {pageMode === PageMode.Full && (
        <>
          <PaddedBox orange>
            <Grid2 container>
              <Grid2 display="flex" alignItems="center">
                <Box>
                  <Typography variant="h3">
                    Report to help deter and stop rape
                  </Typography>
                  <br />
                  <Typography variant="h3">
                    If it just happened, go to the police or a SARC first, if
                    you want to
                  </Typography>
                  <br />
                  <a href={Links.ShouldIReport} target="_blank">
                    <Typography variant="body1">
                      What are my reporting options?
                    </Typography>
                  </a>
                </Box>
              </Grid2>
            </Grid2>
          </PaddedBox>
        </>
      )}
      {pageMode === PageMode.Lite && (
        <>
          <PaddedBox orange>
            <Typography variant="h3">
              “We should not be ashamed and we should speak if we want to”
            </Typography>
          </PaddedBox>
        </>
      )}

      {pageMode !== PageMode.Menu && (
        <ReportFormSection
          onSuccess={() => {
            setShowMessage(true);
            localStorage.setItem("enoughsubmitted", "true");
          }}
          mode={pageMode === PageMode.Full ? "full" : "lite"}
        />
      )}
      {pageMode === PageMode.Lite && <RecoverWithEnoughSection />}
      {showMessage && <WhatNextSection />}
    </PageContainer>
  );
}
