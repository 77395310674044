import { Typography } from "@mui/material";
import React from "react";
import Carousel from "react-material-ui-carousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";

export interface QuoteCarouselProps extends CarouselProps {
  quotes: string[];
}

export default function QuoteCarousel(props: QuoteCarouselProps) {
  const { quotes } = props;
  return (
    <Carousel {...props}>
      {quotes.map((quote, i) => (
        <Typography key={i} variant={"h5"}>
          {`“${quote}”`}
        </Typography>
      ))}
    </Carousel>
  );
}
