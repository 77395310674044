import * as React from "react";
import ThisIsEnoughSection from "./ThisIsEnoughSection";
import LiveWidgetSection from "./LiveWidgetSection";
import PageContainer from "./PageContainer";
import ReportGiveGetSection from "./ReportGiveGetSection";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";
import InstagramSection from "./InstagramSection";
import TestReportRecoverSection from "./TestReportRecoverSection";

export default function HomePage() {
  return (
    <PageContainer gap={0}>
      <InstagramSection />
      <ThisIsEnoughSection />
      <br />
      <TestReportRecoverSection />
      <ReportGiveGetSection />
      <LiveWidgetSection />
      <RecoverWithEnoughSection />
    </PageContainer>
  );
}
