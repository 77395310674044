import * as React from "react";
import { Stack, Typography } from "@mui/material";
import PaddedBox from "./PaddedBox";
import FAQKitSection from "./FAQKitSection";
import PageContainer from "./PageContainer";
import FAQReportingSection from "./FAQReportingSection";
import FAQEnoughSection from "./FAQEnoughSection";
import FAQRecoverySection from "./FAQRecoverySection";
import FAQPoliceSection from "./FAQPoliceSection";
import { Link } from "react-router-dom";
import FAQOtherSection from "./FAQOtherSection";

export default function FAQPage() {
  return (
    <PageContainer>
      <PaddedBox sx={{ pt: 3, px: 4 }}>
        <Stack gap={8}>
          <FAQPoliceSection />
          <FAQEnoughSection />
          <FAQKitSection />
          <FAQReportingSection />
          <FAQRecoverySection />
          <FAQOtherSection />
        </Stack>
      </PaddedBox>
      <PaddedBox>
        <Typography variant="body2">
          You can find further FAQs written by the Faculty of Forensic and Legal
          Medicine here (p7-9):{"   "}
          <Link
            to="https://fflm.ac.uk/wp-content/uploads/2024/09/Joint-Position-statement-on-forensic-self-swab-services-after-sexual-assault-or-rape-with-FAQ-10-09-2024-v3.pdf"
            target="_blank"
          >
            https://fflm.ac.uk/wp-content/uploads/2024/09/Joint-Position-statement-on-forensic-self-swab-services-after-sexual-assault-or-rape-with-FAQ-10-09-2024-v3.pdf
          </Link>
        </Typography>
      </PaddedBox>
    </PageContainer>
  );
}
