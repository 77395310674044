import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQRecoverySection() {
  return (
    <FAQSection title="Recovery">
      <FAQ title="What does recovery involve?">
        <Typography variant="body2">
          There’s three parts: therapy style videos on YouTube, Instagram, and
          Tik Tok, an online community/forum, and guides for friends and family.
        </Typography>
      </FAQ>
      <FAQ title="Can I access the community?">
        <Typography variant="body2">
          The community is for survivors and close friends/family. You can sign
          up for an account on our website.
        </Typography>
      </FAQ>
      <FAQ title="Does enough have a therapist?">
        <Typography variant="body2">
          We have a Clinical Lead called Maisie. She creates the videos, manages
          the community and runs livestreams but she does not do 1 on 1
          sessions. Our recovery services are free of charge and that can’t
          extend to 1 on 1 conversations. We have information on other places
          where you can get that support on our website.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
