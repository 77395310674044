import * as React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import kit from "../img/kit2.png";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

export default function ThisIsEnoughSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox orange sx={{ mt: 0 }}>
      <Grid2 container>
        <Grid2 xs={12}>
          <Typography
            color="common.white"
            variant="h1"
            sx={{
              mb: 2,
              letterSpacing: 1.2,
              fontSize: large ? "6rem !important" : "3rem !important",
            }}
          >
            enough to end rape
          </Typography>
        </Grid2>
        <Grid2
          xs={large ? 6 : 12}
          sx={{ zIndex: 2 }}
          display="flex"
          alignItems="center"
        >
          <Box sx={{ pr: 3, pb: large ? 0 : 3 }}>
            <Typography
              color="common.white"
              variant="h3"
              sx={{
                fontSize: large ? "2.5rem !important" : "1.5rem !important",
              }}
            >
              We support survivors and deter perpetrators
            </Typography>
            <br />
            {false && (
              <ButtonLink href={Links.About} theme="filled">
                Learn more
              </ButtonLink>
            )}
          </Box>
        </Grid2>
        <Grid2 xs={large ? 6 : 12} display="flex" alignItems="flex-end">
          <Box
            component="img"
            src={kit}
            sx={{ maxWidth: large ? "100%" : "75%" }}
          />
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
