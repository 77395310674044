import React from "react";
import { useContext } from "react";
import { SizeContext } from "./App";
import PaddedBox from "./PaddedBox";
import Typography from "@mui/material/Typography";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ButtonLink from "./ButtonLink";
import { Links } from "../types";

export default function ReportGiveGetSection() {
  const size = useContext(SizeContext);
  const large = size === "large";
  return (
    <PaddedBox sx={{ pt: 3 }}>
      <Grid2 container>
        <Grid2 xs={12} sx={{ mb: 3 }}>
          <Typography
            variant="h1"
            sx={{ fontSize: large ? "10rem !important" : "5rem !important" }}
          >
            8,000
          </Typography>
          <Typography variant="h1">
            deterrent kits distributed to students
          </Typography>
        </Grid2>
        {false && (
          <>
            <Grid2 xs={4} sx={{ pr: large ? 3 : 1, pb: large ? 0 : 2 }}>
              <ButtonLink
                href={Links.Report}
                theme="outlined"
                sx={{ width: large ? "80%" : "100%" }}
              >
                Report
              </ButtonLink>
            </Grid2>
            <Grid2 xs={4} sx={{ pr: large ? 3 : 1, pb: large ? 0 : 2 }}>
              <ButtonLink
                href={Links.Donate}
                theme="outlined"
                sx={{ width: large ? "80%" : "100%" }}
              >
                Give
              </ButtonLink>
            </Grid2>
          </>
        )}
        <Grid2 xs={4} sx={{ pr: large ? 3 : 1, pb: large ? 0 : 2 }}>
          <ButtonLink
            href={Links.Buy}
            theme="outlined"
            sx={{ width: large ? "80%" : "100%" }}
          >
            Get kits
          </ButtonLink>
        </Grid2>
      </Grid2>
    </PaddedBox>
  );
}
