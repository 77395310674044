import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQEnoughSection() {
  return (
    <FAQSection title="Enough">
      <FAQ title="Is enough about making money?">
        <Typography variant="body2">
          No. Enough is not for profit. We put all money made back into
          increasing access of kits to students and survivors.
        </Typography>
      </FAQ>

      <FAQ title="Why is Enough selling kits?">
        <Typography variant="body2">
          Many people across the UK have requested kits. £20 is the lowest price
          we can provide them for to also cover the cost of lab testing.
        </Typography>
      </FAQ>

      <FAQ title="Are enough  kits available outside the UK?">
        <Typography variant="body2">
          Our kits are only currently available in the UK.
        </Typography>
      </FAQ>
      <FAQ title="What is the kit?">
        <Typography variant="body2">
          A DNA swab, a water vial and instructions, for you to use discreetly
          and quickly
        </Typography>
        <br />
        <Typography variant="body2">
          A visible deterrent to perpetrators to prevent rape
        </Typography>
        <br />
        <Typography variant="body2">
          A symbol of a world without sexual violence
        </Typography>
      </FAQ>

      <FAQ title="I ordered a kit online. When will it arrive?">
        <Typography variant="body2">
          Kits ordered before 2.00pm Mon-Fri will be posted first class on the
          same day, usually arriving the next day (Tuesdays to Saturdays).
        </Typography>
      </FAQ>

      <FAQ title="Where can I use the kit?">
        <Typography variant="body2">
          You can take a sample from: Vagina, anus, vulva, mouth, skin. The best
          results are from liquid e.g. sperm/saliva, even if now dry.
        </Typography>
      </FAQ>
      <FAQ title="Is the kit for all genders?">
        <Typography variant="body2">
          Yes. The kit is for all genders. Our kit instructions explain how to
          use the swab in different areas.
        </Typography>
      </FAQ>
      <FAQ title="How long do the kits last? ">
        <Typography variant="body2">
          You should get a new one every 4 years. There’s an expiry date on the
          outside of the swab.
        </Typography>
      </FAQ>
      <FAQ title="What does it detect?">
        <Typography variant="body2">
          It shows the presence of someone else’s DNA in or on your body. It
          picks up semen, saliva or persistent touch (sweat). It works even with
          a condom because of persistent touch.
        </Typography>
      </FAQ>
      <FAQ title="What if I’ve showered?">
        <Typography variant="body2">
          You can still use it. Some samples can be taken up to 7 days after.
          Read the user guide for more info.
        </Typography>
      </FAQ>
      <FAQ title="Where should I keep the kit before I use it?">
        <Typography variant="body2">
          Anywhere that will be room temp or below (max 22°C.)
        </Typography>
      </FAQ>
      <FAQ title="How is this different to what exists today?">
        <Typography variant="body2">
          DNA testing for rape is currently only possible at the police or a
          sexual assault referral centre (SARC). The examination is far more
          extensive and is stronger forensically, but we exist because many want
          a way of testing at home simply and discreetly.{" "}
        </Typography>
      </FAQ>
      <FAQ title="My test results haven’t come yet?">
        <Typography variant="body2">
          Make a note of the date you posted it. If you have not heard back
          within 5 working days please email tom@myenough.com.
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
