import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQReportingSection() {
  return (
    <FAQSection title="Reporting">
      <FAQ title="How can I write an anonymous statement?">
        <Typography variant="body2">
          Via the report page of our website{" "}
        </Typography>
      </FAQ>

      <FAQ title="Why do you publish every anonymous statement?">
        <Typography variant="body2">
          To help create prevention and deterrence. We verify every single one
          before posting. We do not allow any names or swear words.{" "}
        </Typography>
      </FAQ>

      <FAQ title="Where do you publish the statements?">
        <Typography variant="body2">
          On our website, Instagram and TikTok
        </Typography>
      </FAQ>
    </FAQSection>
  );
}
