import * as React from "react";
import PageContainer from "./PageContainer";
import AdmissibilitySection from "./AdmissibilitySection";

export default function AdmissibilitySummaryPage() {
  return (
    <PageContainer>
      <AdmissibilitySection />
    </PageContainer>
  );
}
