import * as React from "react";
import { Typography } from "@mui/material";
import FAQ from "./FAQ";
import FAQSection from "./FAQSection";

export default function FAQOtherSection() {
  return (
    <FAQSection>
      <FAQ title="Can I be an ambassador?">
        <Typography variant="body2">
          Yes, via the application form in our instagram bio.
        </Typography>
        <br />
        <Typography variant="body2">Email us at:</Typography>
        <Typography variant="body2">tom@myenough.com</Typography>
        <Typography variant="body2">katie@myenough.com</Typography>
      </FAQ>
    </FAQSection>
  );
}
