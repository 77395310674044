import React, { useContext, useEffect, useState } from "react";
import { authContext } from "./AuthProvider";
import { AuthenticationResultType } from "@aws-sdk/client-cognito-identity-provider";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  Link,
  Checkbox,
  FormControl,
  FormControlLabel,
  Button,
} from "@mui/material";
import CognitoLoginForm from "./CognitoLoginForm";
import { LoginDTO } from "../types";

export interface LoginModalProps {
  open: boolean;
  handleLoginResponse: (response: LoginDTO) => void;
  handleClose: (e: any, reason: string) => void;
  initialFlow: AuthFlow;
  handleAnonSubmit?: () => void;
  defaultTitle?: string;
}

export enum AuthFlow {
  SIGN_IN,
  SIGN_UP,
  CONFIRM,
  PASSWORD_RESET,
  PASSWORD_RESET_CONFIRM,
}

const GOOGLE_ENABLED = false;

export default function LoginModal(props: LoginModalProps) {
  const {
    open,
    handleLoginResponse,
    handleClose,
    handleAnonSubmit,
    defaultTitle,
  } = props;
  const [authFlow, setAuthFlow] = useState(props.initialFlow);
  const [postAnon, setPostAnon] = useState(false);
  const { previousLogin } = useContext(authContext);

  const getAuthTitle = () => {
    switch (authFlow) {
      case AuthFlow.SIGN_IN:
        return "Sign in";
      case AuthFlow.CONFIRM:
        return "Confirmation code";
      case AuthFlow.PASSWORD_RESET:
        return "Password reset";
      case AuthFlow.PASSWORD_RESET_CONFIRM:
        return "Password reset";
      default:
        return defaultTitle || "Sign up";
    }
  };

  const handleCognitoSuccess = (response: AuthenticationResultType) => {
    const idToken = response.IdToken!;
    if (idToken) {
      handleLoginResponse({ idToken, idp: "cognito" });
    }
  };

  const handleGoogleSuccess = (response: CredentialResponse) => {
    const idToken = response.credential!;
    if (idToken) {
      handleLoginResponse({ idToken, idp: "google" });
    }
  };

  useEffect(() => {
    if (previousLogin) setAuthFlow(AuthFlow.SIGN_IN);
  }, [previousLogin]);

  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogContent sx={{ mb: 3 }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle variant="h5">{getAuthTitle()}</DialogTitle>
          <Box justifyContent="flex-end" display="flex">
            <Link
              variant="button"
              component="button"
              sx={{ p: 2, fontSize: 30, textDecoration: "none" }}
              onClick={() => handleClose(null, "none")}
            >
              ×
            </Link>
          </Box>
        </Stack>
        <Stack gap={2}>
          {GOOGLE_ENABLED &&
            !postAnon &&
            (authFlow === AuthFlow.SIGN_IN ||
              authFlow === AuthFlow.SIGN_UP) && (
              <>
                <Box justifyContent="center" display="flex">
                  <GoogleLogin
                    text="continue_with"
                    size="large"
                    onSuccess={handleGoogleSuccess}
                  />
                </Box>
                <Box justifyContent="center" display="flex">
                  <Typography variant="subtitle1">— OR —</Typography>
                </Box>
              </>
            )}
          {handleAnonSubmit && (
            <FormControl>
              <FormControlLabel
                disableTypography
                sx={{
                  fontSize: "1.25rem",
                  mb: 2,
                }}
                control={
                  <Checkbox
                    id="collection-consent"
                    checked={postAnon}
                    onChange={(e) => {
                      setPostAnon(e.target.checked);
                    }}
                  />
                }
                label="Skip account creation"
              />
            </FormControl>
          )}

          {!postAnon && (
            <CognitoLoginForm
              onSuccess={handleCognitoSuccess}
              onSignUpSelect={() => {
                setAuthFlow(AuthFlow.SIGN_UP);
              }}
              onSignInSelect={() => {
                setAuthFlow(AuthFlow.SIGN_IN);
              }}
              onConfirmSelect={() => {
                setAuthFlow(AuthFlow.CONFIRM);
              }}
              onPasswordResetSelect={() => {
                setAuthFlow(AuthFlow.PASSWORD_RESET);
              }}
              onPasswordResetConfirmSelect={() => {
                setAuthFlow(AuthFlow.PASSWORD_RESET_CONFIRM);
              }}
              authFlow={authFlow}
            />
          )}
          {postAnon && handleAnonSubmit && (
            <Button
              onClick={handleAnonSubmit}
              color="secondary"
              variant="contained"
              type="submit"
            >
              <Typography color="white">Submit</Typography>
            </Button>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
