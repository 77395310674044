import * as React from "react";
import { Box, Typography } from "@mui/material";
import FAQ from "./FAQ";

import { Links } from "../types";
import PaddedBox from "./PaddedBox";

export default function AdmissibilitySection() {
  return (
    <PaddedBox>
      <FAQ
        full
        title="Is Enough evidence admissible in Court?"
        variant="subtitle1"
      >
        <Typography variant="caption">
          DNA evidence is only relevant, and will only be submitted to a court,
          if contact with a particular individual and location are disputed
          <br />
          <br />
          If the survivor will testify that the DNA sample was correctly
          collected following the instructions to avoid contamination; then an
          Enough DNA sample can be admissible*
          <br />
          <br />
          Not even police DNA is absolutely 100% guaranteed admissible in court,
          neither is an Enough sample.
          <br />
          <br />
          Enough’s DNA kit was developed, and is produced by, the same people
          that develop and produce kits for the British police
        </Typography>
        <br />
        <Typography variant="caption">
          *Full legal opinion by a leading KC Rape Barrister,{" "}
          <a href={Links.Admissibility}>see here</a>.
        </Typography>
      </FAQ>
    </PaddedBox>
  );
}
