import React from "react";
import StatsSection from "./StatsSection";
import PageContainer from "./PageContainer";
import AboutInfoSection from "./AboutInfoSection";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import ButtonLink from "./ButtonLink";
import RecoverWithEnoughSection from "./RecoverWithEnoughSection";
import { Links } from "../types";
import YouTubeVideo from "./YouTubeVideo";
import OurTeamSection from "./OurTeamSection";

export default function AboutPage() {
  return (
    <PageContainer gap={0}>
      <StatsSection />
      <PaddedBox>
        <Typography variant="body1">
          Enough is a revolutionary form of reporting that gives power to
          survivors and deters perpetrators.
        </Typography>
      </PaddedBox>
      <PaddedBox orange>
        <Typography variant="h1">
          We want to help create a world without rape
        </Typography>
        <br />
        <ButtonLink target="_blank" href={Links.Donate} theme="filled">
          Donate Now
        </ButtonLink>
      </PaddedBox>
      <AboutInfoSection />
      <PaddedBox>
        <Typography variant="h1">Learn more:</Typography>
        <br />
        <YouTubeVideo src="https://www.youtube.com/embed/tOkXz-iFSd8?si=Nim2rflSZZ09BpWV" />
      </PaddedBox>
      <OurTeamSection />
      <RecoverWithEnoughSection />
    </PageContainer>
  );
}
