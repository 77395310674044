import React from "react";
import QuoteCarousel from "./QuoteCarousel";
import { CarouselProps } from "react-material-ui-carousel/dist/components/types";
import quotes from "../quotes";

interface TestimonyCarouselProps extends CarouselProps {}

function shuffle(array: string[]) {
  for (var i = array.length - 1; i >= 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

export default function TestimonyCarousel(props: TestimonyCarouselProps) {
  const old_quotes = [
    "I hope you understand the damage you have done and I’m stronger than that damage and I will take my experiences to keep people safe",
    "I was assaulted in a club, spiked and raped. I'm still scared that every time I go out I'll see him again, yet I go back to the same club every week. I feel crazy and stupid",
    "I lived on frenchay campus in first year. I had three rapsits in freshers, and then met my abuser who lived in the court opposite me. I was raped hundreds of times but I thought it was love. Noone cared even though they knew, because him being their drug dealer was more important than integrity. I went into lectures with bruises on my face but not even my lecturers asked if I was ok.",
    "When I finally got the courage to tell people what had happened, he tried to convince me that it was my fault and that it would ruin his reputation. I was so scared, I told our friends I must have gotten things confused. They treated me as a liar and alienated me.",
    "I was raped by my partner, i doubt he even knows or cares what he did. I was asleep in his bed.",
    "The guilt of not knowing its its my fault, as a male by a male, not wanting to speak up incase it ruins his life over something he may not have understood the severity of",
    "It was easy to let him carry on. My family were home and it was the middle of the night. He was getting annoyed whenever I said stop, I didn’t want to wake anyone. He was a family friend.",
    "I think it was partly my fault I mean i was dumb enough to trust him, to go to his place at 2am, to drink what he gave me",
    "Even though I verbally and physically resisted and he violently proceeded to rape me, I still felt hesitant to tell anyone afterwards, because I had had something to drink that evening and I expected this would influence people to blame me for it (I blamed myself as well).",
    "I always said no but it was never enough",
    "It happened all throughout my relationship but I couldn’t tell anyone as I thought it was normal. It’s not",
    "\"Your beautiful that's why\". That wasn't a compliment that was a threat, an excuse for his actions. He explained it away as a \"man's need\", something he couldn't control.",
    "People believe you have to be evil in order to assault, when in reality, all that has to happen is a misinterpretation in words. Regardless of intent, when consent is ignored or not prioritized, the effects are just as traumatic as an assault with intent to hurt. Check in on how your partner is feeling, make safe words, and always ask when unsure, you cannot mindread.",
    "He was my best friend and although I never said no, I screamed and there was blood dripping onto the floor. It took me weeks until I spoke up and then he got let off with a warning from the uni while I was left with daily nightmares and anxiety. 🧡",
    "I am waiting for the day every cell in my body has regenerated and he has never touched the body he hurt.",
    "I was gang raped at 19 after my first night out - no one stopped it.",
    "I wish I would have told someone when it was happening. I feel so guilty. It’s so hard seeing him every day at school too.",
    "I found out my ex raped me, i didn’t know it was rape at the time. I had told him “While i’m in a sober mindset i really don’t wanna lose my virginity please don’t let us do anything even if i say yes later” He got me high, i couldn’t speak. Then it happened",
    "As someone who thought It would never happen to me and I’d be able to see the signs. I didn’t it was my boyfriend I was 13.",
    "It was Halloween had a bit to much too drink I thought we were all friends and we came to chill at mine. I only rember that I blacked out and that I was in bed my sister in her bed it was my first time and I don’t remember anything but just laying there, and they took me to the shower and I don’t know why it happened. No one ever told me anything and everyone acted like it was fine.",
    "To all of my brothers who struggle alone - you have got this. As a male victim myself, it has been a struggle to find others who have experiences like me. It does get better, speak out and don’t let yourself be silenced!",
    "There is not a day that goes by that I dont think about what happened. I can’t imagine how much worse it would’ve been if I agreed to go back to your dorm room. You still did so much damage even though our friends were asleep only a few feet away.",
    "Sometimes it’s your best friend who you thought cared so much more about you",
    "I didn’t even know it was rape until after. He insisted and I kept saying no and then I gave in because I liked him and didn’t want to upset him. I hated every second of it.",
    "I've never really spoken about it. A lot of my closest friends don't know. It is something that haunts me. I blame myself, even though I know it's not my fault. There's a feeling of shame. How could I let this happen. It was 2 of my dad's coworkers. They drugged me. They told me the next day how funny it was, and how they wished they recorded it.",
    "At the time I didn't understand the severity, if it wasn't for my sister making me go to get tested, I wouldn't have any idea about these services and the importance of them. It took years to process and I am so lucky to be alive after that. I wish I had gone to court and kept my records but I couldn't comprehend why I should. I really felt like it didn't matter, I thought destroying the evidence would make it go away as if I was taking up space knowing I'm not reporting to police. In hindsight it was a mistake and reflection of how I felt about myself. I made uniformed decisions based on temporary emotions. I regret doing so now I'm older and is the one thing I would advise anyone to do. Let them keep your clothes/DNA profiles regardless of how you feel or where you are or what you think you are going to do. You are not wasting time or resources, you can take as many years as you want to make a decision, it's okay to ask questions and it doesn't make you stupid, no one knows what to do the first time they experience this. Take your time, take up space and believe in yourself as much as our community believes you.",
    "Last time it happened, I saw where it was going. I was out at a bar with a family friend, and a guy there wouldnt leave me alone no matter how many times I said no. Eventually I saw that if I didn't say yes, it'd happen with force anyway. So went to the bathroom with him just to get it over with and got blackout drunk right after to try and forget about it. It didn't help much.",
    "**** *****, 20 years old , Sunshine Coast Queensland. You raped me, enough is enough 🧡",
    "don’t ever feel like you’re not worthy of love because of it, you deserve healthy love and it’s not your fault",
    "I felt like I couldn’t come forward or report what had happened because the mutual friends who we met through are still friends with him and I didn’t want to lose them. If I’d known about this kit at the time I would have at least been more able to make that choice. Thank you for creating this❤️",
    "I was so scared, so shamed I let someone I knew do this to me and be frozen and not fight. If I said anything it would blow up both our lives but literally couldn’t sleep without nightmares . But after time I learnt to move on not forget and be more confident not to let someone do that again",
    "They won’t stop us by not believing us, because we as a community believe each other. And together we will make change, by raising our voices",
    "I kept saying no and he kept kissing me and I passed out. When I woke up he was assulting me . He drove me home home and told me not to tell anyone because he'd get battered and I'd get called a slag by everyone",
    "I can’t believe I tried to protect someone by hiding what he did to me when didn’t even care that he raped me, he didn’t care that he did it again. I wish I could go back and be angry in the moment. It took me years to be angry about it. Being ashamed, staying scared, and keeping quiet as the trauma developed into PTSD wasted so many years of my life. If something like that ever happens to anyone, I wish them to be able to be angry like a best friend would. Don’t let shame manifest for you, it’s the person who did it who should be ashamed. You're entitled to be angry at someone ignoring your boundaries and doing as they wish. Logically, you know it. So let yourself feel it.",
    "I hate that I felt sorry for him, sorry for telling people what he had done, when he was the one who felt no remorse",
    "I can’t believe I was scared of ruining his reputation in a world where a sexual predator can become president",
    "I’m unbreakable . He didn’t kill me that night but something inside me did , I grew from it and learned not to let anyone else touch me or hurt me",
    "Just because you don’t verbally say no doesn’t mean it’s okay, freezing and but saying yes is enough. Don’t let anyone invalidate your experience and tell you how to heal from your trauma, only you know the impact that it’s has on you.",
    "It doesnt matter who does or doesnt believe you. You know the truth, so speak it. It may help others who are hiding.",
    "He tried to turn me against myself, but I am stronger than his lies and manipulation. I know the truth and so does he.",
    "I keep telling myself I'm just being dramatic or that it didnt actually happen. Because he was whispering in my ear telling me it was ok and hed be done soon. I can still feel his hands on me. I need help.",
    "There will always be a black dot on my timeline. But that doesn't mean it can't be colourful again.",
    "He eventually decided to grab me and kiss me without my consent and touch me infront of my colleagues. I haven’t felt so weak and powerless in a long time, it felt like my whole world had come crashing down again. But he didn’t stop, even after being told to leave me alone and no countless times he persisted and persisted. I eventually broke down and reported to, it is now being dealt with and he is facing consequences. I don’t feel as ashamed anymore but that feeling of guilt and disgust of my body still lingers. I hope to soon not feel guilty for something HE did to ME.",
    "I told my bfs mum I was raped. She asked me about it the first time I went to his house and I did not want to speak of it. She thought I was lying, so I had to dig through screenshots and messages to try prove I wasn't lying. Ends up it was all for nothing, she didnt believe me still and he left me.",
    '"I know a sound is still a sound around no one". A Fiona apple quote that helped me when I had a highschools worth of people telling me I lied.',
    "When the memories hit, remember it's your skin not theirs. They may have used it but they can't take it.",
    "I’m most scared of myself: what if in my blacked out state I had given the impression that I wanted it? I’d be branded a home wrecker.",
    "I stayed silent about it in the groups but hearing others speak openly about it gave me my voice finally.",
    'Asleep is not a yes. "I just wanted to make you feel beautiful". Rape never makes anyone feel beautiful. It is terrifying and painful. It has been 9 months since what he did to me and I still can\'t sleep.',
    "We all deserve justice and we all deserve to heal. We never deserve what happened to us, but we will get our lives back. We will stand strong again. We are beautiful, handsome and outgoing people. We will be okay. And eventually good. What takes time to hurt, takes time to heal.",
    "I found out and was scared to tell anyone in fear of how they would perceive me",
    "He was never arrested but hopefully it scared him enough to not do it to anyone else",
    "I don’t remember but i know something happened, everyone thinks I’m being dramatic but I know my body",
    "He is still walking free to this day.",
    "Coming to terms with what he did to me was the hardest thing I’ve ever had to do in my life. I couldn’t wish that pain on anyone. There is an after though, one in which I have never been more empowered in my bodily autonomy and sense of self and I wish that for all survivors of sexual violence.",
    "1 year ago I got ra**d. It’s still extremely hard to say the word or think of it however, I’ve come so far, I’ve managed to heal myself more and not think about it every day.",
    "Asleep is not consent. “I didn’t mean it like that” isn’t a valid excuse.",
    "Although reporting him didn’t get him caught, I felt a sense of relief knowing I could potentially help another victim if there should be one.",
    "It wasn’t a stranger in a dark alley. It was overtly violent like in the movies. It was a colleague who abused his power. This or freezing due to psychological threat does not mean your experience isn’t valid.",
    "People around me said “maybe you just didn’t remember” but you know your gut and your body and if something doesn’t look or feel right, chances are something wasn’t right. Lack of memory does not mean you are in the wrong.",
    "I’m not defined by what you did to me, or by how everyone else defined what you did to me.",
    "It may feel like a stain on your body but stains can be covered up and removed eventually. It may take more work but you can recover and reclaim your life back. It takes time, therapy, and support.",
    "Sometimes I wish he was dead not because of what he did to me but so I would know he couldn’t do it to anyone else",
    "I should not be ashamed. YOU should.",
    "Being in a relationship with them doesn’t make it okay.",
    "I hope he feels the pain one day he put me through. I hope he burns in hell.",
    "just because he didn't ask doesn't mean you consented",
    "Rape is often inaccurately portrayed in film/TV. Rather than being a stranger in an alley of a dimly lit street, it can be someone you know and trust. I froze in the moment as a way of self-preservation to get through the horrible situation. I asked myself why I didn’t fight back if I knew I didn’t want to but maybe I wouldn’t have been so harsh on myself and how I reacted in the situation if I was aware rape didn’t necessarily happen in the way it is portrayed in fictional scenes.",
    "My fear was not your permission",
    "What you did to me was wrong in every sense of the word and I know that now",
    "Like Madame Pelicote said, shame needs to change sides. They knew what they were doing and isolated me to action their plan.",
    "I now know not to blame myself for what he did.",
    "It’s anonymous and literally takes five minutes. It’s the no brainer first response",
    "enough gives you time - something you can never get back later",
    "Coercion is rape. Sometimes you freeze. I froze.",
    "He tried to break me, but I will be stronger than him and that",
    "I’ve turned my doubt into recognition that they wronged me",
    "I hope your Mum knows what you did and who you are",
  ];
  return <QuoteCarousel {...props} quotes={shuffle(quotes)} />;
}
