import React, { useContext, useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { SizeContext } from "./App";
import HeaderLogo from "./HeaderLogo";
import MainMenuButton from "./MainMenuButton";
import { Links } from "../types";
import { AppBar, IconButton, Menu, MenuItem, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { authContext } from "./AuthProvider";

import { locationContext } from "./LocationProvider";
import LoginButton from "./LoginButton";

export default function MainMenu() {
  const size = useContext(SizeContext);
  const { accessToken } = useContext(authContext);
  const large = size === "large";

  const location = useLocation();
  const { addLocation } = useContext(locationContext);
  useEffect(() => {
    const path = location.pathname;
    addLocation(path);
  }, [location.pathname]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hamburgerStyle = {
    py: 0,
    m: 0,
    ml: 2,
    justifyContent: "flex-start",
  };

  const isMenuOpen = Boolean(anchorEl);

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ backgroundColor: "common.white", pb: 2 }}
      >
        <HeaderLogo />
        {large ? (
          <Toolbar className="hide-print" sx={{ justifyContent: "center" }}>
            <MainMenuButton href={Links.Kit}>Kit</MainMenuButton>
            <MainMenuButton href={Links.Report}>Report</MainMenuButton>
            <MainMenuButton href={Links.Recovery}>Recovery</MainMenuButton>
            <MainMenuButton href={Links.Allies}>Allies</MainMenuButton>
            <MainMenuButton href={Links.Crisis}>Crisis</MainMenuButton>
            <MainMenuButton href={Links.About}>About</MainMenuButton>
            <MainMenuButton href={Links.Donate}>Donate</MainMenuButton>
            {accessToken!! && (
              <MainMenuButton href={Links.Account}>My Account</MainMenuButton>
            )}
            <LoginButton />
          </Toolbar>
        ) : (
          <Toolbar variant="dense">
            <IconButton
              onClick={handleClick}
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              sx={{ mr: 2 }}
              className="hide-print"
            >
              <MenuIcon className="hide-print" fontSize="large" />
            </IconButton>
          </Toolbar>
        )}
        <Menu
          open={isMenuOpen && !large}
          anchorReference="anchorEl"
          onClose={handleClose}
          anchorEl={anchorEl}
        >
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Kit}>
              Kit
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Report}>
              Report
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Recovery}>
              Recovery
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Allies}>
              Allies
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Crisis}>
              Crisis
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.About}>
              About
            </MainMenuButton>
          </MenuItem>
          <MenuItem>
            <MainMenuButton sx={hamburgerStyle} href={Links.Donate}>
              Donate
            </MainMenuButton>
          </MenuItem>
          {accessToken!! && (
            <MenuItem>
              <MainMenuButton sx={hamburgerStyle} href={Links.Account}>
                My Account
              </MainMenuButton>
            </MenuItem>
          )}
          <MenuItem>
            <LoginButton />
          </MenuItem>
        </Menu>
      </AppBar>
    </>
  );
}
