import React, { createContext, useState } from "react";

interface LocationContext {
  history: string[];
  addLocation: (location: string) => void;
}

export const locationContext = createContext<LocationContext>({
  history: [],
  addLocation: (_location: string) => {},
});

export const LocationProvider = (props: React.PropsWithChildren) => {
  const [history, setHistory] = useState<string[]>([]);

  const addLocation = (location: string) => {
    if (!history.length || history[history.length - 1] !== location) {
      setHistory([...history, location]);
    }
  };

  return (
    <locationContext.Provider value={{ history, addLocation }}>
      {props.children}
    </locationContext.Provider>
  );
};
