import React from "react";
import Typography from "@mui/material/Typography";
import PaddedBox from "./PaddedBox";
import { LinkedIn } from "@mui/icons-material";
import { Box } from "@mui/material";

import katie from "../img/katie_orange.png";
import tom from "../img/tom_orange.png";
import maisie from "../img/maisie_orange.png";

interface TeamCardProps {
  name: string;
  src: string;
  email?: string;
  linkedin: string;
}

function TeamCard(props: TeamCardProps) {
  const { src, name, email, linkedin } = props;
  return (
    <Box display="flex" alignItems="center">
      <img src={src} style={{ width: "25%" }} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ mt: 1 }}
      >
        <Typography variant="body1">{name}</Typography>
        {email!! && (
          <a href={`mailto:${email}`}>
            <Typography variant="caption">{email}</Typography>
          </a>
        )}
        <a href={linkedin} target="_blank">
          <LinkedIn />
        </a>
      </Box>
    </Box>
  );
}

export default function OurTeamSection() {
  return (
    <PaddedBox>
      <Typography variant="h1">Our Team:</Typography>
      <br />
      <Box display="flex" flexDirection="column">
        <TeamCard
          name="Katie White, Co-Founder"
          src={katie}
          email="katie@myenough.com"
          linkedin="https://www.linkedin.com/in/katie-white-378354106/"
        />
        <br />
        <TeamCard
          name="Tom Allchurch, Co-Founder"
          src={tom}
          email="tom@myenough.com"
          linkedin="https://www.linkedin.com/in/tomallchurch/"
        />
        <br />
        <TeamCard
          name="Dr Maisie Johnstone Phd, Clinical Lead"
          src={maisie}
          email="clinicallead@myenough.com"
          linkedin="https://www.linkedin.com/in/dr-maisie-johnstone-74a351134/"
        />
      </Box>
    </PaddedBox>
  );
}
